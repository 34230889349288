import React from 'react';
import { Link,useHistory,NavLink } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import sidebarBg from '../../../assets/images/bg1.jpg';
const AdminAside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
 const history = useHistory();
 const { t, i18n } = useTranslation();
    const signOutHandler = () => {
        localStorage.clear();
        history.push("/")
        window.location.reload();
    }
    const pendingRequest=()=>{
      var full = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
       var newurl= full+'/all-pending-requests';
      
      window.location.href=""+newurl;
      
     }
  return (
    <ProSidebar
     
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
         ADMIN PROFILE
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            icon={<FaTachometerAlt />}
            suffix={<span className="badge red">new</span>}
          >
           Dashboard
           <Link to="/company-home" />
          </MenuItem>
          <MenuItem icon={<FaGem />}> 
          Reports
             <Link to="/admin/maintenance/26" />
          </MenuItem>
		  
		    <MenuItem icon={<FaGem />}> 
          Work Reports
             <Link to="/admin/reports/25" />
          </MenuItem>
        
          <MenuItem
            icon={<FaTachometerAlt />}
            suffix={<span className="badge red">new</span>}
          >
        
           <NavLink onClick={()=>pendingRequest(1)} to="/company-home" exact activeClassName="selected">  { t('Pending Request') }</NavLink>
           {/*<Link onClick={()=>pendingRequest(1)} to="/company-home" />*/}
          </MenuItem>
           <MenuItem icon={<FaGem />}> 
             <NavLink  to="/pending-requests/1" exact activeClassName="selected">   { t('Accepted Requests') }</NavLink>
          
        </MenuItem>
        <MenuItem icon={<FaGem />}>
        
            <NavLink  to="/pending-requests/8" exact activeClassName="selected">  { t('Finished Requests') }</NavLink>
        
         </MenuItem>


         {/**<MenuItem icon={<FaGem />}>Deliver</MenuItem>*/}
        <MenuItem icon={<FaGem />} > Logout
           <Link onClick={signOutHandler}/>
        </MenuItem>
         

        </Menu>
      </SidebarContent>

     
    </ProSidebar>
  );
};

export default AdminAside;
