import React from 'react'
import { Col, Row, FormFeedback, FormGroup, Button, Input, Breadcrumb, BreadcrumbItem, Badge, Label } from "reactstrap";
import Select from "react-select";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import GoogleMap from '../../Components/GoogleMap';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const phoneRegExp = /^(9665|\+9665|5|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/


const country = [
    {
        value: "India",
        label: "India"
    }
];
const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];
const country_codes = [
    {
        value: "966",
        label: "966+"
    },
    {
        value: "91",
        label: "91+"
    }
];

const GetInfo = ({ selected, checkBoxChangeHandler, changeTaskType, changeComponent, sendOTP, selectedService, updateLoctaionCord, gettingData, tasks, selectedtask, selecteDevice, changearabicToEng, defaultLat, defaultLang, mapSel, country_code }) => {

    const [values, setValues] = React.useState({});
    const mobile = localStorage.getItem('mobile');
    const { t, i18n } = useTranslation();

    const validationSchema = Yup.object().shape({



        mobileNumber: Yup.string()
            .required(t("Mobile number is required")),
        //.matches(phoneRegExp, t('Phone number is not valid')),

        task: Yup.string()
            .required(t("Plaese select task type"))


    });


    const submitHandler = (values, resetForm) => {

        gettingData(values);
        changeComponent("gettingInfo2");
        setOpen(false);
        //setOpen(true);
        //sendOTP();

        /**if(mapSel){
          changeComponent("gettingInfo2");
        }**/
        // resetForm({ values: "" });
    };

    const onChangeHandler = (e) => {
        console.log("target", e.target.id)
        changeTaskType(e.target.id, e.target.value);

    };

    const changeArabic = (event, setFieldValue) => {
        console.log("event", event)
        var number = changearabicToEng(event.target.value);
        console.log("afetr conversion", number)
        setFieldValue('mobileNumber', number)
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleYesLocation = () => {
        changeComponent("gettingInfo2");
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };
    let mobilenew = mobile ? parseInt(mobile, 10) : "";


    return (
        <Formik
            initialValues={{
                deviceModel: "",
                mobileNumber: mobilenew != null ? mobilenew : '',
                task: [],
            }}
            validationSchema={validationSchema}
            onSubmit={
                (values, { resetForm }) => submitHandler(values, resetForm)
            }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,

                handleSubmit
            }) => (
                <>

                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"

                    >
                        <DialogTitle id="alert-dialog-slide-title " style={{ display: 'flex' }}>  {t('map_title')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {t('Please choose map')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>

                            <Button onClick={handleYesLocation} className="mr-5 ml-5" color="primary">
                                {t('Yes')}
                            </Button>
                            <Button onClick={handleClose} color="primary">
                                {t('No')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Col md="6">
                        <Row>

                            <Col xs="12">
                                <Row>

                                    <Col xs="12" md="12" lg="12">
                                        <div className="d-flex flex-wrap mb-2">


                                            <div class="top-breadcrumb">
                                                <Badge href="#" color="light">{selectedService}</Badge>
                                                <Badge href="#" color="light">{i18next.language == 'ar' ? <i class="fa fa-arrow-left" aria-hidden="true"></i> : <i class="fa fa-arrow-right" aria-hidden="true"></i>}</Badge>
                                                <Badge href="#" color="warning">{selectedtask}</Badge>
                                            </div>

                                        </div>
                                    </Col>

                                </Row>
                            </Col>



                            <Col xs="12">

                                {i18next.language == 'en' ?
                                    <p className="font-weight-bold pl-2 task-type-head">{t('choose task type')}</p>
                                    :
                                    <p className="font-weight-bold text-right pr-2  task-type-head">{t('choose task type')}</p>
                                }
                                <Row>
                                    {
                                        tasks.map(task => (
                                            <Col key={task.task_id} xs="6" sm="4" md="6" className="d-flex flex-wrap align-items-center" >
                                                <Label className={classnames("flex-grow-1 MenuTabs", { active: values.task.includes(task.task_id) })}>
                                                    <div className="d-flex align-items-center CustomCheckBox">

                                                        <input
                                                            invalid={errors.task && touched.task}
                                                            name="task"
                                                            onChange={e => { handleChange(e); onChangeHandler(e) }}
                                                            id={i18next.language == 'en' ? task.task_title : i18next.language == 'ar' ? task.task_title_ar : task.task_title_fr}
                                                            type="radio"
                                                            className="customInput"
                                                            value={task.task_id}
                                                            label={i18next.language == 'en' ? task.task_title : i18next.language == 'ar' ? task.task_title_ar : task.task_title_fr}
                                                            checked={values.task.includes(task.task_id)}
                                                        />
                                                        {i18next.language == 'en' ? <span className="checkmark"></span> : i18next.language == 'ar' ? <span className="checkmark-ar"></span> : <span className="checkmark"></span>}
                                                        &nbsp;  {i18next.language == 'en' ? task.task_title : i18next.language == 'ar' ? task.task_title_ar : task.task_title_fr}



                                                    </div>

                                                </Label>
                                                <div class="error-info">{touched.task && errors.task}</div>
                                            </Col>

                                        ))

                                    }
                                </Row>



                            </Col>

                            <Col xs="12 ">
                                <Row>
                                    <div class="row-seperate">

                                    </div>

                                </Row>
                            </Col>

                            {/* <Col xs="12">
                                    <div className="mb-2">
                                        <h5>Would Like</h5>
                                        <div>
                                            <CustomCheckBoxLabel
                                                groupName="serviceType"
                                                active={selected}
                                                onChange={checkBoxChangeHandler}
                                                classNames="mr-3"
                                                label="Fix" />
                                            <CustomCheckBoxLabel
                                                groupName="serviceType"
                                                active={selected}
                                                onChange={checkBoxChangeHandler}
                                                classNames="mr-3"
                                                label="Buy Now" />
                                        </div>
                                    </div>
                                </Col>
                                */}
                            <Col xs="12 ">
                                <Row >
                                    <Col xs="12" md="12" lg="12 d-flex">

                                        <span className="pludge-sms">{t('Pledge')}</span>
                                    </Col>

                                </Row>

                                <Row className={i18next.language == 'en' ? 'contact-en' : i18next.language == 'ar' ? 'contact-ar' : 'contact-en'}>
                                    <Col xs="9" md="10" lg="10">
                                        <div className="flex-wrap mt-2">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.mobileNumber && touched.mobileNumber}
                                                    placeholder={t('Mobile No')}
                                                    value={values.mobileNumber}
                                                    onChange={(value) => setFieldValue("mobileNumber", value.value)}
                                                    onChange={ev => changeArabic(ev, setFieldValue)}
                                                    name="mobileNumber"
                                                    type="text"
                                                    maxlength="10"

                                                    onBlur={handleBlur("mobileNumber")}
                                                    className="BoxShadow mb-2 d-block" />
                                                <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col xs="3" md="2" lg="2" className="d-flex pt-2 ">
                                        <span className="country-code">{country_code}</span>
                                    </Col>

                                </Row>
                            </Col>
                            {/** <Col xs="12">
                                    <Row>
                                        <Col xs="6" md="12" lg="6">
                                            <FormGroup>
                                                <Select
                                                    invalid={errors.country && touched.country}
                                                    className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country && errors.country })}
                                                    value={country.find(p => p.value === values.country) || null}
                                                    onChange={(value) => setFieldValue("country", value.value)}
                                                    placeholder={t('Select Country')}
                                                    onBlur={handleBlur("country")}
                                                    name="country"
                                                    options={country} />
                                                <FormFeedback> {touched.country && errors.country}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" md="12" lg="6">
                                            <FormGroup>
                                                <div className="d-flex flex-wrap mb-2">
                                                    <Select
                                                        className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city && errors.city })}
                                                        value={city.find(p => p.value === values.city) || null}
                                                        onChange={(value) => setFieldValue("city", value.value)}
                                                        placeholder={t('Select City')}
                                                        onBlur={handleBlur("city")}
                                                        name="city"
                                                        options={city} />
                                                    <FormFeedback className="w-100">{touched.city && errors.city}</FormFeedback>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>**/}
                            {/* <GoogleMap updateLoctaionCord={updateLoctaionCord} defaultLat={defaultLat} defaultLang={defaultLang} />*/}

                            {/* <Col sm="12" className="text-center mt-3 order-3 mb- d-flex ">

                                <Col className="text-center mt-3 order-3 mb-1 map_box align-bottom align-text-bottom">
                                    <div class="d-inline-block text-map-wrapper">
                                        <div class="map_text">حدد نوع الخدمة وسجل رقم جوالك ثم اختر التالي لإتمام الطلب</div>

                                    </div>
                                </Col>


                            </Col>*/}

                            {/**<Col sm="12">{ mapSel==false ? <div className="pludge-sms">{t('Please selet your location')} </div> :  <div class="success-box"> </div>}</Col>**/}
                            <div class="fixed-botton">
                                <div class="map_text">حدد نوع الخدمة وسجل رقم جوالك ثم اختر التالي لإتمام الطلب</div>

                                <Col sm="12" className="text-center mt-1 order-3 mb-5 d-flex  justify-content-between">


                                    <div class="d-inline-block ">

                                        <Button
                                            className="RequestBtn px-4 ml-10 mb-2 font-weight-bolder"
                                            onClick={() => changeComponent("chooseService")}>
                                            {t('Back')}

                                        </Button>
                                    </div>
                                    <div class="d-inline-block" className="">
                                        <Button
                                            className="RequestBtn px-4 ml-10 mb-2 font-weight-bolder"

                                            onClick={handleSubmit}>
                                            {t('Next')}

                                        </Button>
                                    </div>

                                </Col>
                            </div>
                        </Row>
                    </Col>

                </>
            )}
        </Formik>)
}



export default GetInfo;

