import React from "react";
import { Row, Col } from "reactstrap";
export default function InstructionModalData({  showModal }) {
    return (
        <section className="BoxShadow p-4">
           
            <Row>
                <Col md="12" sm="12" xs="12">
                      <p>dsdsdsdsdsdsd dsdsdsdsd</p>
                   
                </Col>
               
            </Row>
        </section>
    );
};