import React from "react";

const Footer = ({country_code,support_number}) => (
    <div className="footer py-2 text-center mt-1">

    <a  href={"tel:"+country_code+support_number}  target="_blank" rel="noopener noreferrer" className="footerIcon BoxShadow mx-3">
            <i className="fa fa-phone"></i>
        </a>
        <span className="font-weight-bold">({country_code}) {support_number}</span>
        
        <a href={"https://wa.me/"+country_code+support_number} target="_blank" rel="noopener noreferrer" className="footerIcon BoxShadow  ml-3">
            <i className="fa fa-whatsapp"></i>
        </a>
    </div>
);

export default Footer;