import React from "react";
import { Row, Col } from "reactstrap";

export default function ShowModalData({ selectedRecord }) {
console.log("selectedRecord",selectedRecord)
    return (
        <section className="BoxShadow p-4">
            <h4 style={{ color: "#FF5900" }} className="mb-4">Aida Bugg.</h4>
            <Row>
                <Col md="4" sm="6" xs="12">
                    <p className="m-0">Device Id</p>
                    <p className="font-weight-bold">{selectedRecord.request_id}</p>
                </Col>
                <Col md="4" sm="6" xs="12">
                    <p className="m-0">Mobile Number</p>
                    <p className="font-weight-bold">{selectedRecord.customer_mobile1}</p>
                </Col>
                <Col md="4" sm="6" xs="12">
                    <p className="m-0">Device Type</p>
                    <p className="font-weight-bold">{selectedRecord.device_model}</p>
                </Col>
                <Col md="4" sm="6" xs="12">
                    <p className="m-0">Device Status</p>
                    <p className="font-weight-bold">{selectedRecord.title}</p>
                </Col>
                <Col md="4" sm="6" xs="12">
                    <p className="m-0">Ended</p>
                    <p className="font-weight-bold">{selectedRecord.Ended}</p>
                </Col>
                <Col md="4" sm="6" xs="12">
                    <p className="m-0">Device Fixed</p>
                    <p className="font-weight-bold">{selectedRecord.device_fix}</p>
                </Col>
                <Col md="8" className="mt-4">
                    <h6>Explain</h6>
                    <p>{selectedRecord.explanation}</p>
                </Col>
            </Row>
        </section>
    );
};