import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './custom.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from 'react-redux'
import store from './store'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import * as serviceWorker from "./serviceWorker";
import withClearCache from "./ClearCache";
import { CookiesProvider } from "react-cookie";
const ClearCacheComponent = withClearCache(App);

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
});




/**if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`./firebase-messaging-sw.js`)
    .then(function(registration) {
      messaging.useServiceWorker(registration); 
      console.log("Registration successful, scope is:", registration.scope);
	    setTimeout(() => {
                            registration.showNotification(payload.data.title, {
                                body: payload.data.body,
                                data: payload.data.link
                            });
                            registration.update();
                        }, 100);
	  
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}**/
ReactDOM.render(
 <CookiesProvider>
    <BrowserRouter>
         <ClearCacheComponent />
    </BrowserRouter> </CookiesProvider>, document.getElementById('root'));
serviceWorker.register();