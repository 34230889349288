import React from "react";
import Routes from "../../../Routes";
import Header from "../../Components/Header/Header";
import i18next from 'i18next';
export default function Layout(props) {
    console.log("props..layout hhh", props)
    return (
        <>
            <Header />

            <Routes rtoken={props.rtoken} selLanguage={i18next.language} />
        </>
    );
}