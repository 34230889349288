import React from 'react';
import { Row, Col, Input, Button,FormGroup, FormFeedback,Label } from "reactstrap";
import sideImage from "../../../assets/images/Group 426.png";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik } from "formik";
import classnames from "classnames";
import CustomCheckBoxLabelPlain from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabelPlain";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
 
   complaintTxt: Yup.string()
         .required("Please fill complaint text")
      
  
});
const OrderInformation = ({ selectedOrder,sendComplaint,checkBoxChangeHandler,selected,changeComplaintStatus,complaintTextDisabled }) => {
  const { t, i18n } = useTranslation();
    console.log(selectedOrder)

 const submitHandler = (values, resetForm) => {
        sendComplaint(values);
    };

    return (
        <>
        <Formik
            initialValues={{
                complaintTxt: selectedOrder.customer_msg,
                requestId:selectedOrder.request_id,
                complaintType:'No',
                replyCompanyTxt:"",
              
            }}
            validationSchema={validationSchema}
             onSubmit={
                    (values, { resetForm }) => submitHandler(values, resetForm)
                }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                handleSubmit
            }) => (

        <Row className="mt-4">
            <Col md="6">
                <Row className="mb-3">
                    <Col sm="4" className="align-self-center font-weight-bold">
                        {t('Full Name')}
                    </Col>
                    <Col sm="8">
                        <Input className="BoxShadow" value={selectedOrder.error_explain} type="text" disabled />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm="4" className="align-self-center font-weight-bold">
                        {t('Request Id')}  
                    </Col>
                    <Col sm="8">
                        <Input className="BoxShadow" type="text" value={selectedOrder.request_id} disabled />
                    </Col>
                </Row>
                   <Row className="mb-3">
                    <Col sm="4" className="align-self-center font-weight-bold">
                        {t('Device Fixed')}   
                    </Col>
                    <Col sm="8">
                        <Input className="BoxShadow" type="text" value={ i18next.language=='en' ?selectedOrder.customer_disp_text :selectedOrder.customer_disp_text} disabled />
                    </Col>
                </Row>
               
                <Row className="mb-3">
                    <Col sm="4" className="align-self-center font-weight-bold">
                       {t('task type')}      
                    </Col>
                    <Col sm="8">
                        <Input className="BoxShadow" type="text" value= { i18next.language=='en' ?selectedOrder.service_title+">"+selectedOrder.task_title :selectedOrder.service_title_ar+">"+ selectedOrder.task_title_ar} disabled />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm="4" className="align-self-center font-weight-bold">
                        {t('Device Status')}      
                    </Col>
                    <Col sm="8">
                        { selectedOrder.title!=null ? 
                        <Input className="BoxShadow" type="text" value={ i18next.language=='en' ?selectedOrder.customer_disp_text :selectedOrder.customer_disp_text} disabled />
                        :
                         <Input className="BoxShadow" type="text" value={ t('Pending Customer')} disabled />
                         }
                       
                    </Col>
                </Row>
             
                 <Row className="mb-3">
                    <Col sm="4" className="align-self-center font-weight-bold">
                         {t('Device Modal')}   
                    </Col>
                    <Col sm="8">
                        <Input className="BoxShadow" type="text" value={selectedOrder.device_model}  disabled />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm="4" className="align-self-center font-weight-bold">
                        {t('Explain')}    
                    </Col>
                    <Col sm="8">
                        <Input className="BoxShadow" type="text" value={selectedOrder.explain_fix} disabled title={selectedOrder.explain_fix} />
                    </Col>
                </Row>
            </Col>
            
            <Col xs="12">
               
                    <Row>
                    <Col sm="2" className="align-self-center font-weight-bold">

                    {t('Do you have complaint') }
                    </Col>
                    <div>
                    

                     <Col xs="12">
                    
                <FormGroup className="d-flex flex-wrap" >
                 <Label className={classnames("flex-grow-1 MenuTabs", { active: values.complaintType.includes('Yes') })}>
                 <div className={"d-flex align-items-center CustomCheckBox  complaint-status-check"}>
                    <input
                    name="complaintType"
                    onChange={handleChange}
                    value='Yes'
                    className="customInput"
                    type="radio"

                    id={t('Yes')}
                    onBlur={handleBlur}
                    onClick={(e)=>{ changeComplaintStatus(e.target.value)}}
                    checked={values.complaintType === 'Yes'} />
                    { i18next.language=='en' ?  <span className="checkmark"></span> : <span className="checkmark-ar"></span> }
                    {t('Yes')}
                </div>
                </Label>
                <Label className={classnames("flex-grow-1 MenuTabs", { active: values.complaintType.includes('No') })}>
                 <div className={"d-flex align-items-center CustomCheckBox complaint-status-check "}>
                  <input
                    name="complaintType"
                    onChange={handleChange}
                    value='No'
                     className="customInput"
                    type="radio"
                    id={t('No')}
                    onBlur={handleBlur}
                    onClick={(e)=>{ changeComplaintStatus(e.target.value)}}
                    checked={values.complaintType === 'No'} />

                     { i18next.language=='en' ?  <span className="checkmark"></span> : <span className="checkmark-ar"></span> }
                    {t('No')}
                     </div>
                  </Label>    
                 </FormGroup>
                    
 
                    </Col>
                    
                       
                    </div>
                      </Row>
               
            </Col>

            <Col md="12" className="mb-2">
                <Row>
                    <Col sm="2" className="font-weight-bold"  style={{ lineHeight: "2em" }}>
                   
                     </Col>
                    <Col sm="4">

                     <FormGroup>
                        <Input
                            invalid={errors.complaintTxt && touched.complaintTxt}
                            placeholder={t('complaintTxt')}
                            value={values.complaintTxt}
                            onChange={handleChange("complaintTxt")}
                            name="complaintTxt"
                            type="textarea"
                            disabled={complaintTextDisabled ? true : false}
                            onBlur={handleBlur("complaintTxt")}
                            maxlength="100"

                            className="BoxShadow pl-3" />
                        <FormFeedback>{touched.complaintTxt && errors.complaintTxt}</FormFeedback>
                    </FormGroup>

                   
                    </Col>
                     <Col sm="3">
                     <Button className="RequestBtn d-flex" onClick={handleSubmit}>
                        {selectedOrder.complaint_status==1 &&  selectedOrder.company_reply_status==0 ? t('complaint sent') 
                        :
                        selectedOrder.complaint_status==1 && selectedOrder.company_reply_status==1? t('Reply') 
                        :
                        t('Send complaint') 
                        }
                    </Button>      
                     </Col> 
                </Row>
            </Col>



            <Col md="12" className="mb-2">
                <Row>
                    <Col sm="2" className="font-weight-bold"  style={{ lineHeight: "2em" }}>
                      {t('Reply from store') }
                     </Col>
                    <Col sm="4">

                     <FormGroup>
                        <Input
                            invalid={errors.replyCompanyTxt && touched.replyCompanyTxt}
                            
                            value={selectedOrder.company_reply}
                            onChange={handleChange("replyCompanyTxt")}
                            name="replyCompanyTxt"
                            type="textarea"
                            onBlur={handleBlur("replyCompanyTxt")}
                            maxlength="100"
                            className="BoxShadow pl-3" />
                        <FormFeedback>{touched.replyCompanyTxt && errors.replyCompanyTxt}</FormFeedback>
                    </FormGroup>

                   
                    </Col>
                  
                </Row>
            </Col>


           
        </Row>
        )}
        </Formik>
     </>
    )
};

export default OrderInformation;
