import React from "react";
import { Row, Col } from "reactstrap";
import ShowGMap from '../../Components/ShowGMap';
export default function LocationModalData({ selectedOrder,updateLoctaionCord }) {
    return (
        <section className="BoxShadow p-4">
           
            <Row>
                <Col md="12" sm="12" xs="12">
                     <ShowGMap selectedOrder ={ selectedOrder} updateLoctaionCord={updateLoctaionCord} />      
                   
                </Col>
               
            </Row>
        </section>
    );
};