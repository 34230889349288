import React, { useState } from 'react';
import AdminAside from '../../Components/Sidebar/AdminAside';
import AdminDashboard from '../AdminPages/AdminDashboard';

function AdminLayout({ menuid,setLocale }) {
	console.log("admin side..",menuid)
  const [rtl, setRtl] = useState(false);
 
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? 'ar' : 'en');
  };

 
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <div className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
      <AdminAside
        image={image}
        collapsed={collapsed}
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
    

      <AdminDashboard
        image={image}
        toggled={toggled}
        collapsed={collapsed}
        rtl={rtl}
       menuid={menuid}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleRtlChange={handleRtlChange}
        handleImageChange={handleImageChange}
      />
   
    </div>
  );
}

export default AdminLayout;