import React, { Component, useEffect } from 'react';
import Layout from './Container/Pages/Layout/Layout';
import CompanyLayout from './Container/Pages/Layout/CompanyLayout';
import AdminLayout from './Container/Pages/Layout/AdminLayout';
import DriverLayout from './Container/Pages/Layout/DriverLayout';
//import FirstPage from "./Container/Pages/Home/FirstPage";
import Login from "./Container/Pages/Login/Login";
import { Switch, Route } from 'react-router-dom';
import SignUp from './Container/Pages/SignUp';
import ToastNotify from "./Container/Components/ToastNotify";
import RedirectUrl from "./Container/Components/RedirectUrl/RedirectUrl";
import Loader from "./Loader";
import { Redirect } from 'react-router';
import i18next from 'i18next';
import { useTranslation, withTranslation, Trans } from 'react-i18next';



// Containers
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

export default function App() {
  const { t, i18n } = useTranslation();

  const [notificationDetails, setNotificationDetails] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    document.body.dir = i18n.dir();
    let timer1 = setTimeout(() => setLoading(false), 2000)

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timer1)
    }


  })
  const message1 = i18next.language == "en" ? " Immediate Maintenance and Services" : i18next.language == "ar" ? "صيانة وخدمات فورية" : "Maintenance et services immédiats";
  const message2 = i18next.language == "en" ? " Select the service and we will contact you immediately" : i18next.language == "ar" ? "حدد الخدمة وسنصل إليك عاجلا" : "Sélectionnez le service et nous vous contacterons immédiatement";
  const message3 = i18next.language == "en" ? "Loading services…." : i18next.language == "ar" ? "جاري تحميل الخدمات...." : "Chargement des services en cours….";
  if (loading) return <Loader message1={message1} message2={message2} message3={message3} />
  // console.log("notification ..",this.state.notificationDetails);
  return (
    <>

      <ToastNotify show={show} message={notificationDetails} setShow={setShow} />
      <React.Suspense fallback={loading}>

        <Switch>

          <Route path="/login" component={Login} />



          <Route exact path="/android" render={(props) => {
            return (
              <RedirectUrl url={"https://play.google.com/store/apps/details?id=com.niddaa"} />)
          }} />

          <Route path="/company-home" component={CompanyLayout} />


          <Route path="/driver-home" component={DriverLayout} />

          <Route exact path="/maintenance/:menuId" render={(props) => {
            return (
              <CompanyLayout menuid={props.match.params.menuId} />)
          }} />

          <Route exact path="/company/complaints/:menuId/:cstatusid" render={(props) => {
            return (
              <CompanyLayout menuid={props.match.params.menuId} cstatusid={props.match.params.cstatusid} />)
          }} />

          <Route exact path="/pending-requests/:statusid" render={(props) => {
            return (
              <CompanyLayout statusid={props.match.params.statusid} />)
          }} />

          <Route exact path="/company/profile/:profileid" render={(props) => {
            return (
              <CompanyLayout profileid={props.match.params.profileid} />)
          }} />

          <Route exact path="/driver/profile/:profileid" render={(props) => {
            return (
              <DriverLayout profileid={props.match.params.profileid} />)
          }} />

          <Route exact path="/reset-password/:rtoken" render={(props) => {
            return (
              <Layout rtoken={props.match.params.rtoken} />)
          }} />

          <Route exact path="/driver/pending-requests/:statusid" render={(props) => {
            return (
              <DriverLayout statusid={props.match.params.statusid} />)
          }} />

          <Route path="/admin-home" component={AdminLayout} />
          <Route exact path="/admin/reports/:menuId" render={(props) => {
            return (
              <AdminLayout menuid={props.match.params.menuId} />)
          }} />
          <Route exact path="/admin/maintenance/:menuId" render={(props) => {
            return (
              <AdminLayout menuid={props.match.params.menuId} />)
          }} />


          <Route path="/" component={Layout} />



        </Switch>
      </React.Suspense>
    </>
  );

};

