import React from 'react'
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import { Col, Row, Button, FormGroup, FormFeedback } from "reactstrap";
import MobileImage from "../../../assets/images/mobile.png"
import ElevatorImage from "../../../assets/images/elevator.png"
import AirConditionerImage from "../../../assets/images/ac.png"
import ElectricityImage from "../../../assets/images/electricity.png"
import CarImage from "../../../assets/images/car.png"
//import PlumbingImage from "../../../assets/images/Group 223.png"
import PlumbingImage from "../../../assets/images/plumb.png"
import CameraImage from "../../../assets/images/camera.png"
import RouterImage from "../../../assets/images/router.png"
import LaundaryImage from "../../../assets/images/laundary.png"
import Select from "react-select";
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { useEffect } from "react";
const devices = [
    { id: 1, label: "Mobile", image: MobileImage },
    { id: 2, label: "Elevator", image: ElevatorImage },
    { id: 3, label: "Air Condition", image: AirConditionerImage },
    { id: 4, label: "Electricity", image: ElectricityImage },
    { id: 5, label: "Plumbing", image: PlumbingImage },
    { id: 6, label: "Cars", image: CarImage },
    { id: 7, label: "Camera", image: CameraImage },
    { id: 8, label: "Network", image: RouterImage }
];



const validationSchema = Yup.object().shape({
    country_id: Yup.string()
        .required("Country is Required"),
    city_id: Yup.string()
        .required("City is Required"),
    mobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .min(1000000000, "Enter valid mobile number")
        .max(9999999999, "Enter valid mobile number")
        .required("Mobile number is required"),
    /** deviceModel: Yup.string()
         .min(3, "Enter valid device number")
         .required("Device Model is required")
         **/
});

const country = [
    {
        value: "India",
        label: "India"
    }
];
const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];

const ChooseServices = ({ checkBoxChangeHandler, checkBoxChangeServiceHandler, country, city, city_id, country_id, selected, defaultService, services, filterServices, changeComponent, filterCities }) => {

    const { t, i18n } = useTranslation();


    useEffect(() => {
        document.body.dir = i18n.dir();
    })

    console.log("city_id..", city_id);
    const submitHandler = (values, resetForm) => {
        /// gettingData(values);
        console.log(values);
        changeComponent("gettingInfo2");
        // resetForm({ values: "" });
    };

    return (
        <Formik
            initialValues={{
                country_id: country_id,
                city_id: city_id,
                mobileNumber: "",
                deviceModel: ""
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={
                (values, { resetForm }) => submitHandler(values, resetForm)
            }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
            }) => (

                <>

                    <Col sm="6" md="6" className="mb-4">


                        <Row>
                            <Col xs="6" md="6" lg="6">
                                <FormGroup>
                                    <Select
                                        invalid={errors.country && touched.country}
                                        className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country_id && errors.country_id })}
                                        value={country.find(p => p.value === values.country_id) || null}
                                        onChange={(value) => filterCities(value.value, setFieldValue)}
                                        //onChange={(value) =>   setFieldValue("country_id", value.value)}
                                        placeholder={t('Country')}
                                        onBlur={handleBlur("country")}
                                        name="country_id"
                                        options={country} />
                                    <FormFeedback> {touched.country_id && errors.country_id}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col xs="6" md="6" lg="6">
                                <FormGroup>
                                    <div className="d-flex flex-wrap mb-2">
                                        <Select
                                            className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city_id && errors.city_id })}
                                            value={city.find(p => p.value === values.city_id) || null}
                                            onChange={(value) => filterServices(value.value, setFieldValue)}
                                            //placeholder="Select City..."
                                            onBlur={handleBlur("city")}
                                            name="city_id"
                                            options={city} />
                                        <FormFeedback className="w-100">{touched.city_id && errors.city_id}</FormFeedback>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* <div className="mb-2 d-flex justify-content-between">
                
                    <div style={{ margin:"auto" }}>
                        <CustomCheckBoxLabel
                            groupName="serviceType"
                            active={defaultService}
                            onChange={checkBoxChangeHandler}
                            classNames="mr-1 ml-1"
                            value="Fix"
                            label={t('Fix')} />
                        <CustomCheckBoxLabel
                            groupName="serviceType"
                            active={defaultService}
                            onChange={checkBoxChangeHandler}
                            classNames="mr-3 ml-3"
                            value="Buy New"
                            label={t('Buy New')}/>

                        <CustomCheckBoxLabel
                            groupName="serviceType"
                            active={defaultService}
                            onChange={checkBoxChangeHandler}
                            classNames="mr-2 ml-2"
                            value="Services"
                            label={t('Services')} />
                    </div>
                     </div>*/}
                        <h5 class="home-service-title"><marquee behavior="scroll" direction={i18next.language == "en" ? "left" : i18next.language == "ar" ? "right" : "left"} scrollamount="8">{t('Service Title')}</marquee></h5>
                        <Row className="mt-1  justify-content-center-sm service-listing">
                            {
                                services.length > 0 ? services.map(service => (

                                    <Col xs="3" sm="3" md="3" lg="3" className="p-0 d-flex mx-0 my-1" key={service.service_id}>
                                        <CustomCheckBoxLabel
                                            extraStyle="min-width-xs-100"
                                            divStyle="CustomDivStyle p-2 py-3 justify-content-center"

                                            image={service.image_title}
                                            value={service.service_id}
                                            groupName="deviceType"
                                            active={selected}
                                            classNames="mt-1 ImageStyle"
                                            label={i18next.language == "en" ? t(service.service_title) : i18next.language == "ar" ? t(service.service_title_ar) : t(service.service_title_fr)}
                                            onChange={checkBoxChangeServiceHandler} />
                                    </Col>
                                ))
                                    :
                                    <Col xs="12" sm="12" md="12" lg="12" className="p-0 d-flex mx-0 my-1" >
                                        <p>{t('Noservice')} </p>
                                    </Col>
                            }
                        </Row>
                    </Col>
                    {/**<Col sm="12" className="text-center mt-3 order-3 mb-5">
                <Button
                    className="RequestBtn px-4 mb-2 font-weight-bolder"
                    onClick={() => changeComponent("")}>
                    Next
                </Button>
            </Col>**/}
                </>
            )}
        </Formik>)
};

export default ChooseServices;
