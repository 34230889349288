import React, { useState } from 'react';
import AsideDriver from '../../Components/Sidebar/AsideDriver';
import Dashboard from '../DriverPages/Dashboard';
import Maintenance from '../Maintenance/Maintenance';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
function DriverLayout({ menuid,setLocale ,statusid,profileid}) {
	
  const [rtl, setRtl] = useState(false);
  const [dashCountSummary, setDashCountSummary] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? 'ar' : 'en');
  };

 const handleDashCountSummary = (seting) => {
    setDashCountSummary(seting);
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (

    <div className={`app ${i18next.language=="ar" ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
      <AsideDriver
        image={image}
        collapsed={collapsed}
        rtl={i18next.language=="ar" ? 'true' :'false' }
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
    

      <Dashboard
        image={image}
        toggled={toggled}
        collapsed={collapsed}
        rtl={rtl}
        dashCountSummary={dashCountSummary}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleRtlChange={handleRtlChange}
        handleImageChange={handleImageChange}
        statusid={statusid}
        profileid={profileid}
        menuid={menuid}
        handleDashCountSummary={handleDashCountSummary}
      />
   
    </div>
  );
}

export default DriverLayout;