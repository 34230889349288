import React from 'react';
import { Row, Col, Input, Button } from "reactstrap";
import sideImage from "../../../assets/images/Group 426.png";
import GoogleMap from '../../Components/GoogleMap';
const SelectDriver = ({ selectedOrder,changeComponent }) => {
    //console.log(selectedOrder)
    return (
        <Row className="mt-4">
            <Col md="8">
        
                <Row className="mb-3">
                    <Col xs="12" sm="12" className="align-self-center font-weight-bold">
						<h2>Select How to contact customers</h2>
                    </Col>
                    
                </Row>
				
				<Row className="mb-3">
                    <Col xs="6" sm="6" className="align-self-center font-weight-bold">
					    <div className="text-center">
                            <Button className="SelectBtns">
                                Company Technition
                            </Button>
                        </div>
                    </Col>
                     <Col xs="6" sm="6" className="align-self-center font-weight-bold">
                        <div className="text-center">
                            <Button className="SelectBtns">
                                Company Driver
                            </Button>
                        </div>
                    </Col>
                     <Col xs="6" sm="6" className="align-self-center font-weight-bold next-row">
                        <div className="text-center">
                            <Button className="SelectBtns">
                                Outside Driver
                            </Button>
                        </div>
                    </Col>
                     <Col xs="6" sm="6" className="align-self-center font-weight-bold next-row">
                        <div className="text-center">
                            <Button className="SelectBtns">
                               Other Way
                            </Button>
                        </div>
                    </Col>
                    
                </Row>
                <Row className="mb-3">
                     <Col xs="6" sm="3" className="align-self-center font-weight-bold">
                        <span className=" d-inline-block" style={{ minWidth: "93px" }}>Collect Amount </span>
                       </Col>
                     <Col xs="6" sm="9" className="align-self-center font-weight-bold">
                        <span className="mx-2">
                             <Input
                                     placeholder="Amount"
                                  
                                    name="clarify"
                                    type="text"
                                   
                                    className="BoxShadow mb-1" />
                        </span>
                   </Col>

                 <Col sm="6" xs="6"  className="align-self-center font-weight-bold"> 
                    
                     <div className="text-center">
                            <Button className="SelectBtns">
                                Save
                            </Button>
                       </div>
                   
                    </Col>
                   
                </Row>
				
               {/* <Row className="mb-3" className="apply-store-btns">
                    <Col sm="6" xs="6" className="align-self-center font-weight-bold">
                    
                     <div className="text-center">
                        <Button className="RequestBtn">
                           Accept
                        </Button>
                    </div>
                   </Col>
                    <Col sm="6" xs="6"  className="align-self-center font-weight-bold"> 
                    
                     <div className="text-center">
                            <Button className="RequestBtn">
                                 Deny
                            </Button>
                       </div>
                   
                    </Col>
                   
                </Row>
               */}
               
               
               
            </Col>
           
        </Row>
    )
};

export default SelectDriver;
