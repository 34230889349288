import React from "react";
import { NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";


const HeaderLink = ({ t,linkName, link, imgPath,  hideToggler, ...props }) => (

    <NavItem className="mb-0" onClick={hideToggler} >
        <NavLink exact to={link} className="NavLink" activeClassName="active">
            <img src={imgPath} className="menuImage" alt="" />
            {linkName}
        </NavLink>
    </NavItem>
);
export default HeaderLink;