import React from "react";
import { Row, Col, Input, Button,PaginationItem,
    PaginationLink } from "reactstrap";
import i18next from 'i18next';
import Pagination from "react-js-pagination";
import axios from 'axios';

import { useTranslation } from 'react-i18next';
const SearchOrder = ({ requests, handleClick ,activePage,page,itemsCountPerPag,totalItemsCount,handlePageChange,trackMobile}) => {
   const { t, i18n } = useTranslation();

    return (
        <>
		{/*<Col xs="12" sm="6" className="mx-auto my-2 d-flex align-items-center">
                <span className="mr-2">Name</span>
                <Input className="BoxShadow" type="text" value={searchedDevice[0].name} disabled />
		</Col>*/}
            {requests.map(order => (
              

                <React.Fragment key={order.request_id + Math.random()}>
                    <Col xs="12" className="BoxShadow  py-2 my-2">
                        <Row>
                            <Col xs="10">

                                <Row>
                                    <Col xs="6" sm="6" className="d-flex">
                                        <span className="mx-1" style={{ fontSize: '14px' }}>{order.request_id}</span>
                                         <span className="mx-1" style={{ fontSize: 'xx-medium' }}>
                                           {order.req_status_id=="1" ? <a onClick={()=>trackMobile(order,1)} ><p class="call-button"><i class="fa fa-phone-square" aria-hidden="true"></i></p></a> : <i class="fa fa-phone-square" aria-hidden="true"></i> }
                                      
                                        </span>
														
                                    </Col>
                                    <Col xs="6" sm="6" className="d-flex align-items-center px-0">

											 <span className="mx-1" style={{  fontSize: '14', color: order.driver_status==1 ? 'green' : 'orange'}} > {order.driver_status=="1" ? t('Accepted') : t('Pending Requests')}</span>
											<span className="mx-2" style={{ fontSize: '14', color:'red' }}>{i18next.language=="en" ? order.title : order.title_ar }</span>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="8"  sm="6" className="align-self-center pr-3 d-flex">
                                         <span className="ml-1 " style={{ fontSize: 'xx-medium',color:'green' }}>
                                         {order.req_status_id=="1" ?  <a onClick={()=>trackMobile(order,2)}  style={{ fontSize: 'xx-medium',color:'green' }}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a>:  <i class="fa fa-whatsapp" aria-hidden="true"></i> }
                                        </span>
                                        <span className="mx-1">{i18next.language=="en" ? order.service_title : order.service_title_ar }</span><span className="mx-1">{i18next.language=="en" ? <i class="fa fa-angle-right" aria-hidden="true"></i>: <i class="fa fa-angle-left" aria-hidden="true"></i>} </span> {i18next.language=="en" ? order.task_title : order.task_title_ar }
                                    </Col>
									
                                    <Col xs="4"  sm="6" className="d-flex align-items-center">
                                  <span className="mx-1">  <i class="fa fa-clock-o" aria-hidden="true"></i></span> <span>{order.fixing_date? order.fixing_date :  order.order_date? order.order_date.split(':')[1]+":"+order.order_date.split(':')[2] : ''}</span>
                                       
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="2" className="d-flex btn-end">
                                <Button className="RequestBtn" style={{ borderRadius: 10 }} onClick={() => handleClick(order)}>
                                  {i18next.language=="en" ?  <i className="fa fa-chevron-right"></i> : <i className="fa fa-chevron-left"></i> }
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </React.Fragment>
            ))}
             <Pagination
                          activePage={activePage}
                          itemsCountPerPage={8}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={3}
                          itemClass="page-item" 
                          linkClass="page-link" 
                          onChange={handlePageChange}
                        />

        </>
    );
}

export default SearchOrder;