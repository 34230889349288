import React from 'react';
import Switch from 'react-switch';
import { Row, Col, Input, Button, FormGroup ,FormFeedback} from "reactstrap";
import { FaHeart, FaBars } from 'react-icons/fa';
import reactLogo from '../../../assets/images/newlogo.png';
import ListMaintanances from './ListMaintanances';
import CompanyProfile from './CompanyProfile';
import Maintenance from '../Maintenance/Maintenance';
import Complaints from '../Complaints/Complaints';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const Dashboard = ({
  collapsed,
  rtl,
  image,
  dashCountSummary,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  handleDashCountSummary,
  statusid,
  menuid,
  profileid,
  cstatusid
}) => {
 const { t, i18n } = useTranslation();
  return (
    <main>
     <div class="header-wrapper-company">
      <header>
	  
		<div className="block top-company-header">
		 <div className="btn-toggle d-flex" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
       <div class="d-flex">
      
         <h5>{t('Company Dashboard')}</h5>
     
        </div>
		  <div class="d-flex logo-img-company">
		  
          <img width={80} src={reactLogo} alt="react logo" />
     
        </div>
      </div>
       
        
      </header>
      </div>
     
       


         <Row>

        <Col>
          { profileid ==undefined && menuid!=25 && menuid!=26?
            <ListMaintanances  handleDashCountSummary={ handleDashCountSummary} statusid={statusid} profileid={profileid} />
            :
           menuid ==25 ? 
             <Maintenance/>
            :
            menuid ==26 ? 
             <Complaints cstatusid={cstatusid}/>
                       
            :
            <CompanyProfile  profileid={profileid} />
          }

        </Col>


      </Row>
	 
      
    </main>
  );
};

export default Dashboard;