import React, { Component } from "react";
import {  Toast } from 'react-bootstrap';

const ToastBox = ({message,setShow,show}) => {	
	
console.log("Show..toast",show)
 
  return (
				<div
                  aria-live="polite"
                  aria-atomic="true"
                  style={{
                    position: 'absolute',
                  
                    
                    left: '50%',
                    position:'fixed',
                    transform: 'translate(-50%, 0px)',
                    zIndex: '9999',
                    width: '100%'

                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                 <div className="outerwrapper" class={show? 'outerwrapper show' : ''} >
                  <Toast
                    style={{
                    
                      top: 0,
                      right: 0,
                       zIndex: '9999',
                       position: 'relative',

                    }}
                    onClose={() => setShow(false)} show={show} delay={2000}
                  >
                    <Toast.Header>
                      <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                      <strong className="mr-auto"></strong>
                     
                    </Toast.Header>
                    <Toast.Body>

                       <p className="m-0 text-muted text-center">
                          {
                              message
                           }
                      </p>
                        
                    </Toast.Body>
                  </Toast>
                   <div class="reqoverlay"></div>
                </div>
                </div>
			);

}
export default ToastBox;