import React from 'react';
import Switch from 'react-switch';
import { Row, Col, Input, Button, FormGroup ,FormFeedback} from "reactstrap";
import { FaHeart, FaBars } from 'react-icons/fa';
import reactLogo from '../../../assets/images/newlogo.png';
import AdminPage from './adminPage';
import ListMaintanances from './ListMaintanances';
import AdminReports from './AdminReports';
import Maintenance from './Maintenance';
const AdminDashboard = ({
  collapsed,
  rtl,
  image,
  dashCountSummary,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  handleDashCountSummary,
  statusid,
  profileid,
   menuid,
}) => {

  return (
    <main>
      <div class="header-wrapper-company">
      <header>
	  
		<div className="block ">
		 <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
		{/**<div class="switch-wrapper">
        <Switch
          height={16}
          width={30}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={handleCollapsedChange}
          checked={collapsed}
          onColor="#219de9"
          offColor="#bbbbbb"
        />
       </div>**/}
		   <h1>
         Admin Page
        </h1>
      </div>
       
        
      </header>
        </div>
     
          {/** dashCountSummary ==true ? 
           <Row>
               <Col md="12">
                 <Row className="mb-12" >
                     <Col xs="12" sm="2" className="align-self-center font-weight-bold total-box bxColorBlack mx-2 mb-2 BoxShadow ">
                          <span className=" d-inline-block" style={{ minWidth: "93px" }}>Total Requests </span>:
                          <span className="mx-2">1000</span>
                      </Col>
                    <Col xs="12" sm="2" className="align-self-center font-weight-bold total-box bxColorYellow  mx-2  mb-2 BoxShadow ">
                       <span className=" d-inline-block" style={{ minWidth: "93px" }}>Total Accept </span>:
                       <span className="mx-2">1000</span>
                               
                    </Col>

                     <Col xs="12" sm="2" className="align-self-center font-weight-bold total-box bxColorRed  mx-2  mb-2 BoxShadow ">
                       <span className=" d-inline-block" style={{ minWidth: "93px" }}>Total Deny </span>:
                       <span className="mx-2">1000</span>
                               
                    </Col>

                     <Col xs="12" sm="2" className="align-self-center font-weight-bold total-box bxColorRed  mx-2  mb-2 BoxShadow ">
                       <span className=" d-inline-block" style={{ minWidth: "93px" }}>Total Ignore </span>:
                       <span className="mx-2">1000</span>
                               
                    </Col>
                   
                </Row>

               </Col>
                            
           </Row>
            : 
            ""
          **/ }  



        {/* <Row>

        <Col>
         
          <AdminPage />
           
        </Col>


      </Row>**/}
	   <Row>

        <Col>
        { profileid ==undefined && menuid!=25 && menuid!=26?
            <ListMaintanances  handleDashCountSummary={ handleDashCountSummary} statusid={statusid} profileid={profileid} />
            :
           
           menuid ==25 ? 
             <AdminReports/>
            :
             menuid ==26 ? 
             <Maintenance/>
             :
           ""
          }

        </Col>


      </Row>
   
      
    </main>
  );
};

export default AdminDashboard;