import { useState, useRef, useEffect } from "react";
 
function useVisible(initialIsVisible) {

  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);
 const [isOpen, setIsOpen] = useState(initialIsVisible);
  const handleClickOutside = (event) => {

  console.log("event..",ref.current);
  console.log("open..",isOpen);
    if (ref.current && !ref.current.contains(event.target)) {
    console.log("clicked otsude");
      setIsOpen(false);
       //event.stopPropagation();
    }
  };
 
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
 
  return { ref, isOpen, setIsOpen };
}
 
export default useVisible;