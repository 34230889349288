import React, { Component } from "react";
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import sideImage from "../../../assets/images/Group 426.png";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import GoogleMap from '../../Components/GoogleMap';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { Post,Get } from "../../../utilities/axiosRequest";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const phoneRegExp =/^(9665|\+9665|5|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/

const validationSchema = Yup.object().shape({
  
   
    username: Yup.string()
        .min(3, "Enter more than 3 characters")
        .max(14, "Allow only 25 characters")
        .required(i18next.lang=="en" ? "Username is required" : "اسم المستخدم مطلوب"),
        
               
     password: Yup.string()
        .trim("No white space allow in starting")
        .required(i18next.lang=="en" ? "Password is required" : "كلمة المرور مطلوبة")
        .min(8, 'Seems a bit short...')
         .max(14, 'We prefer insecure system, try a shorter password.')
         .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
     sponserMobileNumber: Yup.string()
        .required(i18next.lang=="en" ? "Mobile number is required" : "رقم الجوال مطلوب")
        .matches(phoneRegExp, i18next.lang=="en" ? 'Phone number is not valid' : ' رقم الجوال خطأ'),
    confirmPassword: Yup.string()
        .trim("No white space allow in starting")
        .required("Confirm Password is required")
        .test('passwords-match', i18next.lang=="en" ? 'Passwords must match' :'كلمة السر غير مطابقة', function(value) {
            return this.parent.password === value;
        }),
    sponserName: Yup.string()
            .required("Plaese enter sponserName"),
   
   
  
});


const country = [
    {
        value: "India",
        label: "India"
    }
];


const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];
const checkAvailabilityUsername=(username)=>{

 
}


const JoinPage2 = ({ selected, checkBoxChangeHandler,registerCompany,country,message, city, updateLoctaionCord,gettingData,changeComponent,spinnerLoading,userDuplicate, validateUsername, defaultLat, defaultLng,changeArabicSponsorNumber,country_code}) => {
     const { t, i18n } = useTranslation();
    const submitHandler = (values, resetForm) => {
      
        

        gettingData(values);
        registerCompany(values);
        console.log(values);
      
    };

      
    return (
        <Formik
                
			  initialValues={{
               
                username: "",
              
              
               
                sponserName:"",
            }}

   

                validationSchema={validationSchema}
                onSubmit={
                   
                     (values, { resetForm }) => submitHandler(values, resetForm)
                }>
                {({
                    errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    validateField,
                    validateForm 
                }) => (
                        <Container>
                            <Row>
                                <Col md="6" className="mb-3">
                                    <Row>
                                  
                                      
                                         <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.company_email && touched.company_email}
                                                    placeholder={t('Email Address')}
                                                    value={values.company_email}
                                                    onChange={handleChange}
                                                    name="company_email"
                                                    type="text"
                                                    maxlangth="80"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.company_email && errors.company_email}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.sponserName && touched.sponserName}
                                                    placeholder={t('Sponser Name')}
                                                    value={values.sponserName}
                                                    onChange={handleChange}
                                                    name="sponserName"
                                                     maxlangth="20"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.sponserName && errors.sponserName}</FormFeedback>
                                            </FormGroup>
                                        </Col>

                                         <Col xs="12" className="mb-3">  
                                         <Row>
                                                      <Col xs="9" md="9" lg="9">
                                                       <div className="d-flex flex-wrap mt-2">
                                                        
                                                           <FormGroup>
                                                            <Input
                                                                invalid={errors.sponserMobileNumber && touched.sponserMobileNumber}
                                                                placeholder={t('Mobile Number')}
                                                                value={values.sponserMobileNumber}
                                                                onChange={(value) => setFieldValue("sponserMobileNumber", value.value)}
                                                                onChange={ev => changeArabicSponsorNumber (ev, setFieldValue)}
                                                                name="sponserMobileNumber"
                                                                type="text"
                                                                maxlength="10"

                                                                onBlur={handleBlur("sponserMobileNumber")}
                                                                className="BoxShadow mb-2 d-block" />
                                                            <FormFeedback>{touched.sponserMobileNumber && errors.sponserMobileNumber}</FormFeedback>
                                                             </FormGroup>
                                                        </div>
                                                    </Col>
                                                     <Col xs="3" md="3" lg="3" className="d-flex pt-2 ">
                                                          <span className="country-code-edit">{country_code}+</span>
                                                     </Col>

                                                </Row>
                                           </Col>


                                        {/**<Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.sponserMobileNumber && touched.sponserMobileNumber}
                                                    placeholder={t('Mobile Number')}
                                                    value={values.sponserMobileNumber}
                                                    onChange={handleChange}
                                                    name="sponserMobileNumber"
                                                     maxlangth="10"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.sponserMobileNumber && errors.sponserMobileNumber}</FormFeedback>
                                            </FormGroup>
                                        </Col>**/}
                                      
										  <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.username && touched.username || userDuplicate}
                                                    placeholder={t('Username')}
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    name="username"
                                                    maxlength="13"
                                                    type="text"
                                                    onBlur={e => {
                                                               
                                                                handleBlur(e);
                                                                validateUsername(e.target.value)
                                                    }}
                                                  
                                                    className="BoxShadow" />
                                               <FormFeedback>{touched.username && errors.username}</FormFeedback>
                                                { userDuplicate && 
                                                <FormFeedback>Username already taken</FormFeedback>
                                                }

                                                
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.password && touched.password}
                                                    placeholder={t('Password')}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    name="password"
                                                    type="password"
                                                    maxlength="14"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.password && errors.password}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.confirmPassword && touched.confirmPassword}
                                                    placeholder={t('Confirm Password')}
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    name="confirmPassword"
                                                    type="password"
                                                    maxlength="14"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.confirmPassword && errors.confirmPassword}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                       {/**<Col xs="6" md="12" lg="6">
                                            <FormGroup>
                                                <Select
                                                    invalid={errors.country && touched.country}
                                                    className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country && errors.country })}
                                                   value={country.find(p => p.value === values.country_id) || null}
                                                   onChange={(value) =>   setFieldValue("country_id", value.value)}
                                                    placeholder={t('"Select Country')}
                                                    onBlur={handleBlur("country")}
                                                    name="country_id"
                                                    options={country} />
                                                <FormFeedback> {touched.country && errors.country}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" md="12" lg="6">
                                            <FormGroup>
                                                <div className="d-flex flex-wrap mb-2">
                                                    <Select
                                                        className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city && errors.city })}
                                                        value={city.find(p => p.value === values.city_id) || null}
                                                        onChange={(value) =>  setFieldValue("city_id", value.value)}
                                                        placeholder={t('Select City')}
                                                        onBlur={handleBlur("city")}
                                                        name="city_id"
                                                        options={city} />
                                                    <FormFeedback className="w-100">{touched.city && errors.city}</FormFeedback>
                                                </div>
                                            </FormGroup>
                                        </Col>**/}

                                         
                                    </Row>
                                      <GoogleMap updateLoctaionCord={updateLoctaionCord}  defaultLat={defaultLat} defaultLang={defaultLng} />
                                        
                                </Col>


                               
                               
                                <Col md="6" className="d-none d-md-block mb-3 align-self-center">
                                    <img src={sideImage} alt="" className="img-fluid" />
                                </Col>
                               {/* <Col sm="12" className="text-center">
                                    <Button
                                        className="RequestBtn px-4 mb-2 font-weight-bolder"
                                        onClick={handleSubmit}>
                                            Next
                                        </Button>
                                 
                                </Col>*/}

                                  <Col sm="6" className="text-center mt-3 order-3 mb-5 d-flex  justify-content-between">
                                 <div class="d-inline-block mr-10 ">
                                    <Button
                                        className="RequestBtn px-4 ml-10 mb-2 font-weight-bolder"
                                         onClick={() => changeComponent("joinPage")}>
                                         {t('Back')}
                                        
                                    </Button>
                                 </div>
                                    <div class="d-inline-block"  className="">
                                    <Button
                                        className="RequestBtn px-4 ml-10 mb-2 font-weight-bolder"
                                         
                                           onClick={handleSubmit}>
                                       {t('Signup')}
                                      
                                    </Button>
                                  </div>
                        </Col>


                                 <Col sm="12" className="text-center mt-3 order-3 mb-2">
                             <Loader
                             type="Circles"
                             color="#00BFFF"
                             height={100}
                             width={100}
                             
                             visible={spinnerLoading}
                     
                          />

                        </Col>
                            
                            </Row>
                        </Container>
                    )
                }
            </Formik>)
}



export default JoinPage2;

