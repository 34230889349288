import React from "react";
import { Col, FormGroup, Input, FormFeedback, Button, FormText, Label, Row, Modal, ModalBody, ModalHeader } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import checkFileTooBig from "../../Components/checkFileTooBig";
// import classnames from "classnames";
// import DatePicker from "react-datepicker";
import { Formik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import InstructionModalData from "./InstructionModalData";
const GetInfo2 = ({ selected, demandType, sendRequestForm, contactType, taskTypes, changeComponent, onFileChange, onFileUpload, selectedDate, dateChangeHandler, checkBoxChangeHandler, gettingData, onCaptchaChange, spinnerLoading, showModal, togglePop, delivery_title, fileSizeError, verifyOTP, otpMsg, otpresult, customer_id, showExistOTP, sendOTP, reSendOTP, resendmessage1 }) => {

    const { t, i18n } = useTranslation();

    const FILE_SIZE = 2 * 1024;
    const validationSchema = Yup.object().shape({
        file: Yup.array()
            .test('is-correct-file', 'VALIDATION_FIELD_FILE_BIG', checkFileTooBig)

    });
    const token = localStorage.getItem("token");
    const message1 = token ? "Sent Succesfully,We will contact you soon" : "Sent Succesfully";
    const message2 = token ? "Sent Succesfully,We will contact you soon" : "Will contact you in urgent.";
    const submitHandler = (values, resetForm) => {
        gettingData(values);
        if (!otpresult) {
            sendRequestForm(values);
        } else {
            console.log("Otp result", otpresult);

        }
        this.setState({ file: values.file })
    };
    return (
        <Formik
            initialValues={{
                clarify: "",
                token: localStorage.getItem("token"),
                customer_id: customer_id,

                otpText: "",


            }}
            validationSchema={validationSchema}
            onSubmit={
                // (values, { resetForm }) => submittingData( values )
                (values, { resetForm }) => submitHandler(values, resetForm)
            }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,


            }) => (
                <>
                    {
                        showModal ?
                            <Modal isOpen={showModal ? true : false} backdrop="static" className="modalStyle">
                                <ModalHeader toggle={togglePop}>Instruction Details</ModalHeader>
                                <ModalBody>
                                    <InstructionModalData showModal={showModal} />
                                </ModalBody>
                            </Modal> : null



                    }
                    <Col md="6">
                        <Row>
                            <Col xs="12">
                                <p className="request-instruction"> {t('instruction-req')}</p>

                            </Col>

                            <Col xs="12">
                                <div className="mb-2">
                                    {taskTypes.length ? <h5>Would Like</h5> : ''}

                                    <div>
                                        {
                                            taskTypes.map(type => (

                                                <CustomCheckBoxLabel
                                                    groupName="taskType"
                                                    active={selected}
                                                    onChange={checkBoxChangeHandler}
                                                    classNames="mr-3"
                                                    label={type.task_title} />

                                            ))

                                        }

                                        {/** <CustomCheckBoxLabel
                                                groupName="serviceType"
                                                active={selected}
                                                onChange={checkBoxChangeHandler}
                                                classNames="mr-3"
                                                label="Fix" />
                                            <CustomCheckBoxLabel
                                                groupName="serviceType"
                                                active={selected}
                                                onChange={checkBoxChangeHandler}
                                                classNames="mr-3"
                                                label="Buy Now" />**/}
                                    </div>
                                </div>
                            </Col>

                            <Col xs="12">
                                <Row>

                                    <Col xs="12" md="12" lg="12">
                                        <FormGroup>
                                            <Input
                                                invalid={errors.deviceModel && touched.deviceModel}
                                                placeholder={t('Device Modal')}
                                                value={values.deviceModel}
                                                onChange={handleChange("deviceModel")}
                                                name="deviceModel"
                                                maxlength="100"
                                                rows="2"
                                                type="text"
                                                onBlur={handleBlur("deviceModel")}
                                                className="BoxShadow mb-2" />
                                            <FormFeedback>{touched.deviceModel && errors.deviceModel}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                        <FormGroup>
                            <Input
                                invalid={errors.clarify && touched.clarify}
                                placeholder={t('Clarify')}
                                value={values.clarify}
                                onChange={handleChange("clarify")}
                                name="clarify"
                                type="textarea"
                                maxlength="500"
                                rows="7"
                                onBlur={handleBlur("clarify")}
                                className="BoxShadow mb-2" />
                            <FormFeedback>{touched.clarify && errors.clarify}</FormFeedback>
                        </FormGroup>

                        {/*<FormGroup>
                                  <Button style="display:block;width:120px; height:30px;" onclick="document.getElementById('file').click()">Your text here</Button>
                                  <Input type="file" name="file" id="file" style={{display:'none'}}
                                   
                                    onChange={ (e) =>   onFileChange(e.target.files) }
                                    
                               />

                              </FormGroup>**/}
                        {/** <FormGroup>
                                 
                                    <Input type="file" name="file" id="file"  invalid={ fileSizeError !='' ? 'true' : 'false' }
                                    onChange={ (e) =>   onFileChange(e.target.files) }
                                    
                               />
                                 <FormFeedback>{ fileSizeError}</FormFeedback>
                              </FormGroup>**/}

                        {/**<FormGroup>
                                    <ReCAPTCHA
                                        sitekey="6Lcw8fUZAAAAAA5iL8sOlHfbkaFYeU3r8DzSvJ1W"
                                        onChange={onCaptchaChange}
                                      />

                              </FormGroup>**/}

                        <Row>

                            <Col xs="12" md="12" lg="12" className="delivery-cost">

                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: delivery_title
                                    }}></span>
                            </Col>
                        </Row>




                        {/** <Row>
                                       
                                   <Col  xs="12" md="12" lg="6" className="delivery-cost">
                                    
                           
                                       <span>I have read instructions</span><a href="#" onClick={togglePop} >Click here</a>
                                    </Col>
                            </Row>**/}

                        {/**<Row>
                                       <Col  xs="6" md="6" lg="6">
                                           <span className="otp-entry-text">أدخل الرقم المرسل إلى جوالك</span>
                                        </Col>


                                        <Col  xs="6" md="6" lg="6">
                                            <FormGroup>
                                                <Input
                                                    invalid={otpresult}
                                                    placeholder={t('Otp Text')}
                                                    value={values.otpText}
                                                    onChange={handleChange("otpText")}
                                                    name="otpText"
                                                    maxlength="25"
                                                    type="text"
                                                    onBlur={(e)=>verifyOTP(e.target.value)}
                                                    className="BoxShadow mb-2" />
                                           
                                                <FormFeedback className={otpresult==false? 'success-box' : 'error-box'}>{otpMsg}</FormFeedback>
                                               { otpresult==false && <div class="success-box">{otpMsg}</div> }
                                            </FormGroup>
                                        </Col>
                              </Row>**/}
                        <input name="token" value={token} type="hidden" />
                        <input name="customer_id" value={customer_id} type="hidden" />
                    </Col>
                    {/*   {showExistOTP &&
                         <Col sm="12" className="text-center mt-1 order-3 mb-0 d-flex">
                              <div class="success-box">{resendmessage1}</div>

                          </Col>
                          }

                         {showExistOTP &&
                        
                          <Col sm="12" className="text-center mt-1 order-3 mb-0 d-flex   justify-content-between">


                                 <div class="d-inline-block mr-10 ">

                                    <Row>
                                       <Col  xs="12" md="12" lg="12">
                                           
                                           <span className="red-text-box">رقم التأكد أرسل مسبقا إلى جوالك أو </span><a className="loading-resend"  onClick={()=>reSendOTP()}> إضغط هنا</a> <span className='red-text-box'> لإعادة إرسال رقم آخر</span>
                                        </Col>
                                    </Row>


                                  </div>


                          </Col>
                          }

                        
                          <Col sm="12" className="text-center mt-3 order-3 mb-1 d-flex   justify-content-between">


                              

                                 <div class="d-inline-block mr-10 ">
                                  

                                 
                                    <Row>
                                       <Col  xs="8" md="8" lg="8">
                                           <span className="otp-entry-text">أدخل الرقم المرسل إلى جوالك</span>
                                        </Col>


                                        <Col  xs="4" md="4" lg="4">
                                            <FormGroup>
                                                <Input
                                                    invalid={otpresult}
                                                    placeholder={t('Otp Text')}
                                                    value={values.otpText}
                                                    onChange={handleChange("otpText")}
                                                    name="otpText"
                                                    maxlength="25"
                                                    type="text"
                                                    onBlur={handleBlur("otpText")}
                                                    className="BoxShadow mb-2" />
                                           
                                                <FormFeedback className={otpresult==false? 'success-box' : 'error-box'}>{otpMsg}</FormFeedback>
                                              
                                            </FormGroup>
                                          
                                        </Col>
                              </Row>
                               </div>
                        </Col>            
                                **/}



                    <Col sm="12" className="text-center mt-1 order-3 d-flex  justify-content-between">

                        <Row>

                            <Col sm="12" className="text-center mt-5 mb-1 order-3 d-flex ">

                                <span>ارسل الطلب الان ليتم التواصل معك عاجلا </span>
                            </Col>
                        </Row>
                    </Col>



                    <Col sm="6" className="text-center order-3 mb-5 mt-2 d-flex  justify-content-between">




                        <div class="d-inline-block mr-10 ">

                            <Button
                                className="RequestBtn px-4 ml-10 mb-2 font-weight-bolder"
                                onClick={() => changeComponent("gettingInfo1")}>
                                {t('Back')}

                            </Button>

                        </div>
                        {fileSizeError == '' &&
                            <div class="d-inline-block" className="">
                                <Button
                                    className="RequestBtn px-4 mb-2 font-weight-bolder"
                                    onClick={handleSubmit}>
                                    {t('Request')}

                                </Button>
                            </div>
                        }
                    </Col>

                    <Col sm="12" className="text-center mt-3 order-3 mb-2">
                        <Loader
                            type="Circles"
                            color="#00BFFF"
                            height={100}
                            width={100}

                            visible={spinnerLoading}

                        />

                    </Col>
                </>
            )}
        </Formik>
    )
};

export default GetInfo2;