import React from "react";
import { Label } from "reactstrap";
import "./CustomCheckBoxLabelCss.css";
import classnames from "classnames";
//import MobileImage from "../../../assets/images/mobile.png"
import i18next from 'i18next';
const CustomCheckBoxLabelPlain = (props) => {
    const { label, classNames, value, groupName, onChange, active, onBlur, image, divStyle, extraStyle, invalid } = props;
    return (

        <Label className={classnames("mt-2 pl-3 pr-1", extraStyle, classNames, { active: active === value })} >


            <div className={i18next.language == "en" ? 'pr-2 d-flex align-items-center CustomCheckBoxPlain ' : 'pl-4 d-flex align-items-center CustomCheckBoxPlain '} >

                {
                    groupName === "deviceType" &&

                    <div><img src={require(`../../../assets/images/${image}`)} alt="" className={"deviceImage img-fluid"} /></div>
                }
                <input
                    name={groupName}
                    onChange={onChange}
                    value={value}
                    className={classnames("customInput", { "d-none": groupName === "deviceType" })}
                    type="radio"
                    invalid={invalid}
                    onBlur={onBlur}
                    checked={active === value} />


                {i18next.language == "en" ?
                    <span className={classnames("checkmark", { "d-none": groupName === "deviceType" })}></span>
                    :
                    i18next.language == "ar" ?
                        <span className={classnames("ml-2 checkmark-ar", { "d-none": groupName === "deviceType" })}></span>
                        :
                        <span className={classnames("checkmark", { "d-none": groupName === "deviceType" })}></span>
                }

                {label}
            </div>
        </Label>

    );
}
export default CustomCheckBoxLabelPlain;