import React from 'react';
import { Row, Col, Input, Button, FormGroup ,FormFeedback} from "reactstrap";
import Select from "react-select";
import sideImage from "../../../assets/images/Group 426.png";
import classnames from "classnames";
import GoogleMap from '../../Components/GoogleMap';
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import NavTabs from "../../Components/Tab/TabPanel";
import { Formik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
    country: Yup.string()
        .required("Country is Required"),
    city: Yup.string()
        .required("City is Required"),
    mobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .min(1000000000, "Enter valid mobile number")
        .max(9999999999, "Enter valid mobile number")
        .required("Mobile number is required"),
    deviceModel: Yup.string()
        .min(3, "Enter valid device number")
        .required("Device Model is required")
});
const country = [
    {
        value: "India",
        label: "India"
    }
];
const RequestInfo = ({ selectedOrder, changeComponent,fixingstatus,checkBoxChangeHandler,saveRequestDetails,updateLoctaionCord,showModal,togglePop,assignRequestTo,assignTo,showlocation,modalTogglerHandler,infoLocation,show,message,setShow,denyRequest}) => {
    const submitHandler = (values, resetForm) => {
        selectedOrder(values);
        console.log(values);
        changeComponent("gettingInfo2");
    };
    return (
    <Formik
            initialValues={{
                country: "",
                city: "",
                mobileNumber: "",
                deviceModel: ""
            }}
            validationSchema={validationSchema}
            onSubmit={
                (values, { resetForm }) => submitHandler(values, resetForm)
            }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
            }) => (
                    <>
                <Row className="mt-2">
                    <Col md="12">
                        <NavTabs changeComponent={changeComponent} saveRequestDetails ={saveRequestDetails} fixingstatus ={fixingstatus} selectedOrder={selectedOrder}  checkBoxChangeHandler={checkBoxChangeHandler} showModal={showModal} togglePop={togglePop} assignRequestTo={assignRequestTo} assignTo={assignTo} showlocation={showlocation} modalTogglerHandler={modalTogglerHandler} infoLocation={infoLocation} updateLoctaionCord={updateLoctaionCord} show={show} message={message} setShow={setShow} denyRequest={denyRequest} />
                
                       {/* <Row className="mb-3">
                            <Col xs="7" sm="7" className="align-self-center font-weight-bold">
        						<span className=" d-inline-block" style={{ minWidth: "93px" }}>Request Id </span>:
                                <span className="mx-2">{selectedOrder.request_id}</span>
                            </Col>
                            <Col xs="5" sm="5" className="align-self-center font-weight-bold">
                                <span className=" d-inline-block" style={{ minWidth: "93px" }}>{selectedOrder.fixing_date} </span>
                               
                            </Col>
                            
                        </Row>

                        <Row className="mb-3">
                            <Col xs="3" sm="3" className="align-self-center font-weight-bold">
                                <span style={{ minWidth: "93px" }}><i class="fa fa-map-marker" aria-hidden="true"></i> </span>
                               
                            </Col>
                            <Col xs="5" sm="5" className="align-self-center font-weight-bold">
                                <span className=" d-inline-block" style={{ minWidth: "93px" }}>10 Days </span>
                               
                            </Col>
                            <Col xs="4" sm="4" className="align-self-center font-weight-bold">
                               <div className="text-center">
                                <Button
                                className="RequestBtn px-4 mb-2 font-weight-bolder"
                                onClick={() => changeComponent("selectDriver")}>
                                    Deny
                                 </Button>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col sm="4" className="align-self-center font-weight-bold">
                                Name
                            </Col>
                            <Col sm="8">
                                <Input className="BoxShadow" value={selectedOrder.name} type="text" disabled />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm="4" className="align-self-center font-weight-bold">
                                 Phone No
                            </Col>
                            <Col sm="8">
                                <Input className="BoxShadow" type="text"  disabled />
                            </Col>
                        </Row>

                         <Row className="mb-3">
                            <Col sm="4" className="align-self-center font-weight-bold">
                                Clarify
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                <Input
                                    invalid={errors.clarify && touched.clarify}
                                    placeholder="Clarify"
                                    value={values.clarify}
                                    onChange={handleChange("clarify")}
                                    name="clarify"
                                    type="textarea"
                                    onBlur={handleBlur("clarify")}
                                    className="BoxShadow mb-2" />
                                <FormFeedback>{touched.clarify && errors.clarify}</FormFeedback>
                            </FormGroup>
                            </Col>
                             <Col sm="2" className="align-self-center font-weight-bold">
                                <a href="#">Attachements</a>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm="4" className="align-self-center font-weight-bold">
                                Device type
                            </Col>
                            <Col sm="8">
                                <Input className="BoxShadow" type="text" value={selectedOrder.deviceType} disabled />
                            </Col>
                        </Row>
        				
        				<Row className="mb-3">
                             <Col sm="6" className="align-self-center font-weight-bold">
                               Collect from customer
                            </Col>
                            <Col xs="8">
                                <div className="mb-2">
                                  
                                    <div>
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="Yes" />
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="No" />
                                         <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="Nothing" />
                                    </div>
                                </div>
                            </Col>
                             <Col xs="4">
                                <div class="date-wrapper">
                                    20/09/2020 18:10PM
                                </div>
                              </Col>
                        </Row>

                        <Row className="mb-3">
                             <Col sm="6" className="align-self-center font-weight-bold">
                               Arrived to store
                            </Col>
                            <Col xs="8">
                                <div className="mb-2">
                                  
                                    <div>
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="Yes" />
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="No" />
                                         <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="Nothing" />
                                    </div>
                                </div>
                            </Col>
                             <Col xs="4">
                                <div class="date-wrapper">
                                    20/09/2020 18:10PM
                                </div>
                              </Col>
                        </Row>
                        <Row className="mb-3">
                             <Col sm="4" className="align-self-center font-weight-bold">
                              Device Status
                            </Col>

                            <Col md="12" lg="6">
                                            <FormGroup>
                                                <Select
                                                    invalid={errors.country && touched.country}
                                                    className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country && errors.country })}
                                                    value={country.find(p => p.value === values.country) || null}
                                                    onChange={(value) => setFieldValue("country", value.value)}
                                                    placeholder="Select Country..."
                                                    onBlur={handleBlur("country")}
                                                    name="country"
                                                    options={country} />
                                                <FormFeedback> {touched.country && errors.country}</FormFeedback>
                                            </FormGroup>
                             </Col>
                         </Row>
                         <Row className="mb-3">
                            <Col sm="12" className="align-self-center font-weight-bold">
                                How to send to customer
                            </Col>
                           
                        </Row>
                        <Row className="mb-3">
                        <Col xs="6" sm="6" className="align-self-center font-weight-bold">
                            <div className="text-center">
                                <Button className="SelectBtns">
                                    Company Technition
                                </Button>
                            </div>
                        </Col>
                         <Col xs="6" sm="6" className="align-self-center font-weight-bold">
                            <div className="text-center">
                                <Button className="SelectBtns">
                                    Company Driver
                                </Button>
                            </div>
                        </Col>
                         <Col xs="6" sm="6" className="align-self-center font-weight-bold next-row">
                            <div className="text-center">
                                <Button className="SelectBtns">
                                    Outside Driver
                                </Button>
                            </div>
                        </Col>
                         <Col xs="6" sm="6" className="align-self-center font-weight-bold next-row">
                            <div className="text-center">
                                <Button className="SelectBtns">
                                   Other Way
                                </Button>
                            </div>
                        </Col>
                        
                    </Row>

                     <Row className="mb-3">
                             <Col sm="6" className="align-self-center font-weight-bold">
                               Return to customer
                            </Col>
                            <Col xs="8">
                                <div className="mb-2">
                                  
                                    <div>
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="Yes" />
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="No" />
                                         <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="Nothing" />
                                    </div>
                                </div>
                            </Col>
                            <Col xs="4">
                                <div class="date-wrapper">
                                    20/09/2020 18:10PM
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                         <Col xs="6" sm="3" className="align-self-center font-weight-bold">
                            <span className=" d-inline-block" style={{ minWidth: "93px" }}>Collect Amount </span>
                           </Col>
                         <Col xs="6" sm="9" className="align-self-center font-weight-bold">
                            <span className="mx-2">
                                 <Input
                                         placeholder="Amount"
                                      
                                        name="clarify"
                                        type="text"
                                       
                                        className="BoxShadow mb-1" />
                            </span>
                       </Col>
                        </Row>
                    <Row className="mb-3">
                        <Col xs="3" sm="3" className="align-self-center font-weight-bold">
                            <span className=" d-inline-block" style={{ minWidth: "93px" }}>Explain</span>
                        </Col>
                        <Col xs="8" sm="8" className="align-self-center font-weight-bold">
                            <span className="mx-2">
                                <Input
                                         placeholder="Explain"
                                        
                                       
                                        name="clarify"
                                        type="textarea"
                                       
                                        className="BoxShadow mb-2" />
                            
                            </span>
                            
                        </Col>
                    
                     </Row>

                     

                     <Row className="mb-3">
                        <Col xs="3" sm="3" className="align-self-center font-weight-bold">
                            <span className=" d-inline-block" style={{ minWidth: "93px" }}>Customer Message</span>
                        </Col>
                        <Col xs="8" sm="8" className="align-self-center font-weight-bold">
                            <span className="mx-2">
                                <Input
                                         placeholder="Customer Message"
                                        
                                       
                                        name="clarify"
                                        type="textarea"
                                       
                                        className="BoxShadow mb-2" />
                            
                            </span>
                            
                        </Col>
                    
                     </Row>
                      <Row className="mb-3">
                        <Col xs="3" sm="3" className="align-self-center font-weight-bold">
                            <span className=" d-inline-block" style={{ minWidth: "93px" }}>Complaint Solved</span>
                        </Col>
                        <Col xs="3" sm="3" className="align-self-center font-weight-bold">
                             <div className="mb-2">
                                  
                                    <div>
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="Yes" />
                                        <CustomCheckBoxLabel
                                            groupName="serviceType"
                                           
                                            onChange={checkBoxChangeHandler}
                                            classNames="mr-3"
                                            label="No" />
                                       
                                    </div>
                                </div>
                        </Col>
                        <Col xs="6" sm="6" className="align-self-center font-weight-bold">
                                <span className="mx-2">
                                <Input
                                         placeholder="Solved?"
                                        
                                       
                                        name="clarify"
                                        type="textarea"
                                       
                                        className="BoxShadow mb-2" />
                            
                                 </span>
                            
                         </Col>
                    
                     </Row>

                        <Row className="mb-3" className="apply-store-btns">
                            <Col sm="6" xs="6" className="align-self-center font-weight-bold">
                            
                             <div className="text-center">
                               
                                <Button
                                className="RequestBtn px-4 mb-2 font-weight-bolder"
                                onClick={() => changeComponent("selectDriver")}>
                                Save
                            </Button>

                              
                            </div>
                           </Col>
                           
                        </Row>
                       
                       
                       **/}
                       
                    </Col>
                   
                </Row>
      </>
                )}
        </Formik>)

};

export default RequestInfo;
