import React, { Component } from "react";
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import CustomCheckBoxLabelPlain from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabelPlain";
import sideImage from "../../../assets/images/Group 426.png";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import Select from "react-select";
import i18next from 'i18next';
import GoogleMap from '../../Components/GoogleMap';
import { useTranslation } from 'react-i18next';
const phoneRegExp =/^(9665|\+9665|5|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(5, "Enter more than 5 characters")
        .max(25, "Allow only 25 characters")
        .required(i18next.lang=="en" ? "Name is required" : "الإسم مطلوب"),

   
   

    mobileNumber: Yup.string()
        .required(i18next.lang=="en" ? "Mobile number is required" : "رقم الجوال مطلوب")
        .matches(phoneRegExp, i18next.lang=="en" ? 'Phone number is not valid' : ' رقم الجوال خطأ'),
    

    subWorkType: Yup.string()
            .required("Plaese select sub work type"),
    
   
   
   
});


const country = [
    {
        value: "India",
        label: "India"
    }
];

const services = [
    { id: "Mobile", name: "Mobile" },
    { id: "Elevator", name: "Elevator" },
    { id: "A/C", name: "A/C" },
    { id: "Electricity", name: "Electricity" },
    { id: "Plumbing ", name: "Plumbing " },
    { id: "Cars", name: "Cars" },
];
const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];


const JoinPage = ({ selected, checkBoxChangeHandler,services, gettingData, changeComponent,getSubTask, updateLoctaionCord,removeSubTask, seltasktypes,selectedService,singleSelectedService,defaultLat, defaultLng,country,city,filterCities,filterServices,country_code,changeArabic}) => {
    const { t, i18n } = useTranslation();
    const submitHandler = (values, resetForm) => {
      
       //console.log(values);
       gettingData(values);
       changeComponent("joinPage2");

       
    };

    return (
        <Formik
			  initialValues={{
                workType: "Work",
                name: "",
                subWorkType:"Company",
                service: [],
                gender:"",
                mobileNumber: "",
                sponserName: "",
                task:[],
                country_id:1,
                city_id: 1,
            }}
                validationSchema={validationSchema}
                onSubmit={
                    (values, { resetForm }) => submitHandler(values, resetForm)
                }>
                {({
                   errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit
                }) => (
                    <>
                        <Col md="6" className="mb-3">
                                    <Row>
                                        <Col>
                                            <FormGroup className="d-flex flex-wrap">
                                                <CustomCheckBoxLabel
                                                    groupName="workType"
                                                    classNames="flex-grow-1 mx-1"
                                                    label={t('Work')}
                                                    value="Work"
                                                    active={values.workType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <CustomCheckBoxLabel
                                                    groupName="workType"
                                                    label={t('Driver')}
                                                    value="Driver"
                                                    classNames="flex-grow-1 mx-1"
                                                    active={values.workType}
                                                     onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <FormFeedback>{touched.workType && errors.workType}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        {values.workType === "Work" && <Col xs="12">
                                            <FormGroup className="d-flex flex-wrap"  >
                                                <CustomCheckBoxLabelPlain
                                                    invalid={errors.subWorkType && touched.subWorkType}
                                                    groupName="subWorkType"
                                                    classNames="mx-1"
                                                    label={t('Company')}
                                                    value='Company'
                                                    active={values.subWorkType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <CustomCheckBoxLabelPlain
                                                    invalid={errors.subWorkType && touched.subWorkType}
                                                    groupName="subWorkType"
                                                    label={t('An Individual')}
                                                    classNames="mx-1"
                                                     value='Individual'
                                                    active={values.subWorkType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <FormFeedback>{touched.subWorkType && errors.subWorkType}</FormFeedback>
                                                 <div class="error-info">{touched.subWorkType && errors.subWorkType}</div>
                                            </FormGroup>
                                        </Col>}
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.name && touched.name}
                                                    placeholder={t('Full Name')}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    maxlangth="20"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.name && errors.name}</FormFeedback>
                                            </FormGroup>
                                        </Col>

                                         <Col xs="12">
                                                    <Row>
                                                        <Col md="12" lg="6">
                                                           <FormGroup>
                                                            <Select
                                                                invalid={errors.country && touched.country}
                                                                className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country_id && errors.country_id })}
                                                                value={country.find(p => p.value === values.country_id) || null}
                                                                onChange={(value) => filterCities( value.value,setFieldValue)}

                                                                //onChange={(value) =>   setFieldValue("country_id", value.value)}
                                                                placeholder={t('signup')}
                                                                onBlur={handleBlur("country")}
                                                                name="country_id"
                                                                options={country} />
                                                            <FormFeedback> {touched.country_id && errors.country_id}</FormFeedback>
                                                        </FormGroup>
                                                        </Col>
                                                        <Col md="12" lg="6">
                                                            <FormGroup>
                                                                <div className="d-flex flex-wrap mb-2">
                                                                   <Select
                                                                    className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city_id && errors.city_id })}
                                                                    value={city.find(p => p.value === values.city_id) || null}
                                                                
                                                                    //onChange={(value) => setFieldValue("city_id", value.value)}
                                                                    onChange={(value) => filterServices( value.value,setFieldValue)}
                                                                    placeholder="Select City..."
                                                                    onBlur={handleBlur("city")}
                                                                    name="city_id"
                                                                    options={city} />
                                                                <FormFeedback className="w-100">{touched.city_id && errors.city_id}</FormFeedback>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                         

                                         
                                      
                                        <Col xs="12" className="mb-3">  
                                         <Row>
                                                      <Col xs="9" md="9" lg="9">
                                                       <div className="d-flex flex-wrap mt-2">
                                                           <FormGroup>
                                                            <Input
                                                                invalid={errors.mobileNumber && touched.mobileNumber}
                                                                placeholder={t('Mobile No')}
                                                                value={values.mobileNumber}
                                                                onChange={(value) => setFieldValue("mobileNumber", value.value)}
                                                                onChange={ev => changeArabic(ev, setFieldValue)}
                                                                name="mobileNumber"
                                                                type="text"
                                                                maxlength="10"

                                                                onBlur={handleBlur("mobileNumber")}
                                                                className="BoxShadow mb-2 d-block" />
                                                            <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                                             </FormGroup>
                                                        </div>
                                                    </Col>
                                                     <Col xs="3" md="3" lg="3" className="d-flex pt-2 ">
                                                          <span className="country-code">{country_code}+</span>
                                                     </Col>

                                                </Row>
                                           </Col>
                                      
                                       {/** <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.mobileNumber && touched.mobileNumber}
                                                    placeholder={t('Mobile Number')}
                                                    value={values.mobileNumber}
                                                    onChange={handleChange}
                                                    name="mobileNumber"
                                                    type="text"
                                                    maxlangth="10"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                            </FormGroup>
                                        </Col>**/}
                                         {/**<Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.company_email && touched.company_email}
                                                    placeholder={t('Email Address')}
                                                    value={values.company_email}
                                                    onChange={handleChange}
                                                    name="company_email"
                                                    type="text"
                                                    maxlangth="80"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.company_email && errors.company_email}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.sponserName && touched.sponserName}
                                                    placeholder={t('Sponser Name')}
                                                    value={values.sponserName}
                                                    onChange={handleChange}
                                                    name="sponserName"
                                                     maxlangth="20"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.sponserName && errors.sponserName}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.sponserMobileNumber && touched.sponserMobileNumber}
                                                    placeholder={t('Mobile Number')}
                                                    value={values.sponserMobileNumber}
                                                    onChange={handleChange}
                                                    name="sponserMobileNumber"
                                                     maxlangth="10"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.sponserMobileNumber && errors.sponserMobileNumber}</FormFeedback>
                                            </FormGroup>
                                        </Col>**/}
                                        {values.workType === "Work" && <Col sm="12" className="mb-3">
                                            { i18next.language=='en' ?
                                             <p className="font-weight-bold">{t('The areas i mastered')}</p>
                                            :
                                             <p className="font-weight-bold text-right">{t('The areas i mastered')}</p>
                                             }
                                            <Row>
                                                {
                                                    services.map(service => (
                                                        <Col key={service.service_id} xs="6" sm="4" md="6" className="d-flex flex-wrap align-items-center">
                                                            <Label className={classnames("flex-grow-1 MenuTabs", { active: values.service.includes(service.service_id) })}>
                                                                <div className="d-flex align-items-center CustomCheckBox">
                                                                    <input
                                                                        name="service"
                                                                        invalid={errors.service && touched.service}
                                                                        onChange={handleChange}
                                                                        type="checkbox"
                                                                        className="customInput"

                                                                        value={service.service_title}
                                                                        onClick={(e)=>{values.service.includes(service.service_title) ? removeSubTask(e.target.value) : getSubTask(e.target.value)}}
                                                                       
                                                                        checked={values.service.includes(service.service_title)}
                                                                    />
                                                                      
                                                                   { i18next.language=='en' ?  <span className="checkmark"></span> : <span className="checkmark-ar"></span> }
                                                                    { i18next.language=='en' ?  service.service_title : service.service_title_ar }
                                                                </div>

                                                            </Label>
                                                        </Col>
                                                    ))

                                                }

                                            </Row>
                                        </Col>}


                                        {seltasktypes.length>0 && <Col sm="12" className="mb-3">
                                            { i18next.language=='en' ?
                                             <p className="font-weight-bold">{t('Choose select types')}</p>
                                            :
                                             <p className="font-weight-bold text-right">{t('Choose select types')}</p>
                                             }
                                            <Row>
                                                {
                                                    seltasktypes.map(seltask =>(
                                                            
                                                     <p className="font-weight-bold text-right">{seltask.service_title_ar}</p>,
                                                      seltask.tasks!='undefined' && seltask.tasks.map(task => (
                                                      
                                                        <Col key={task.task_id} xs="6" sm="4" md="6" className="d-flex flex-wrap align-items-center">
                                                            <Label className={classnames("flex-grow-1 MenuTabs", { active: values.task.includes(task.task_id) })}>
                                                                <div className="d-flex align-items-center CustomCheckBox">
                                                                    <input
                                                                        name="task"
                                                                        invalid={errors.task && touched.task}
                                                                        onChange={handleChange}
                                                                        type="checkbox"
                                                                        className="customInput"
                                                                        value={task.task_id}
                                                                        checked={values.task.includes(''+task.task_id)}
                                                                    />
                                                                      
                                                                   { i18next.language=='en' ?  <span className="checkmark"></span> : <span className="checkmark-ar"></span> }
                                                                  { i18next.language=='en' ? seltask.service_title : seltask.service_title_ar }  &nbsp;<i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; { i18next.language=='en' ?  task.task_title : task.task_title_ar }
                                                                </div>

                                                            </Label>
                                                        </Col>
                                                   ))
                                             
                                             ) )

                                                }

                                            </Row>
                                        </Col>}


                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.explanation && touched.explanation}
                                                    placeholder={t('Explain company')}
                                                    value={values.explanation}
                                                    onChange={handleChange}
                                                    name="explanation"
                                                    type="textarea"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.explanation && errors.explanation}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                       
                                    </Row>
                                </Col>
                                <Col md="6" className="d-none d-md-block mb-3 align-self-center">
                                    <img src={sideImage} alt="" className="img-fluid" />
                                </Col>


                               

                               <Col sm="12" className="text-center">
                                    <Button
                                        className="RequestBtn px-4 mb-2 font-weight-bolder"
                                        onClick={handleSubmit}>
                                       {t('Next')}
                                        </Button>
                                 
                                </Col>
                       </>  
                    )
                }
            </Formik>)
}



export default JoinPage;

