import React from "react";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const Tooltips = ({msg}) => {	
	

 
 return (
    <div className="App">
      <Tooltip
        title={msg}
        placement="top"
      >
      <a>*</a>
      </Tooltip>
    </div>
  );

}
export default Tooltips;