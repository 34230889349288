import React from "react";
import { useEffect, useRef } from "react";

export default function RedirectUrl(props) {
  console.log("props..layout", props)
  alert(props)
  useEffect(() => {
    window.location.replace(props.url);
  }, [props.url]);
  return (
    <>


      {/*<Routes rtoken={props.rtoken} />*/}
    </>
  );
}