import React, { Component } from "react";
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import sideImage from "../../../assets/images/Group 426.png";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import ShowGMap from '../../Components/ShowGMap';
import ToastBox from "../../Components/ToastBox/ToastBox";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const phoneRegExp =/^(9665|\+9665|5|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/

const validationSchema = Yup.object().shape({
  
    mobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
       
        .required("Mobile number is required"),
     mobileNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid'),
    username: Yup.string()
        .min(3, "Enter more than 3 characters")
        .max(25, "Allow only 25 characters")
        .required("Username is required"),


  
});


const country = [
    {
        value: "India",
        label: "India"
    }
];


const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];


const JoinPage2 = ({ selected, checkBoxChangeHandler,formData, show,setShow,updateDriver,country,message, city, updateLoctaionCord,gettingData,changeComponent}) => {
    const { t, i18n } = useTranslation();
    const submitHandler = (values, resetForm) => {
        console.log("Values....",values)
        gettingData(values);
         updateDriver(values);
     
      
    };

    return (
       <>
        <ToastBox show={show} message={message} setShow={setShow} />  

        <Formik
                
			  initialValues={{
               
                username: formData.username,
                mobileNumber: formData.person_mobile,
              
                country_id:formData.country_id,
                city_id: formData.city_id,
            }}
                validationSchema={validationSchema}
                onSubmit={
                   
                     (values, { resetForm }) => submitHandler(values, resetForm)
                }>
                {({
                    errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    disabled,
                    handleSubmit
                }) => (
                        <Container>
                            <Row>
                                <Col md="6" className="mb-3">
                                    <Row>
                                  
                                      
                                   
                                       
										  <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.username && touched.username}
                                                    placeholder={t('Username')}
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    name="username"
                                                    disabled="true"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.username && errors.username}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.password && touched.password}
                                                    placeholder="Keep no change for blank"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    name="password"
                                                    type="text"

                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.password && errors.password}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.confirmPassword && touched.confirmPassword}
                                                    placeholder={t('ConfirmPassword')}
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    name="confirmPassword"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.confirmPassword && errors.confirmPassword}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" md="12" lg="6">
                                            <FormGroup>
                                                <Select
                                                    invalid={errors.country && touched.country}
                                                    className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country && errors.country })}
                                                   value={country.find(p => p.value === values.country_id) || null}
                                                   onChange={(value) =>   setFieldValue("country_id", value.value)}
                                                    placeholder={t('Select Country')}
                                                    onBlur={handleBlur("country")}
                                                    name="country_id"
                                                    options={country} />
                                                <FormFeedback> {touched.country && errors.country}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" md="12" lg="6">
                                            <FormGroup>
                                                <div className="d-flex flex-wrap mb-2">
                                                    <Select
                                                        className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city && errors.city })}
                                                        value={city.find(p => p.value === values.city_id) || null}
                                                        onChange={(value) =>  setFieldValue("city_id", value.value)}
                                                        placeholder={t('Select City')}
                                                        onBlur={handleBlur("city")}
                                                        name="city_id"
                                                        options={city} />
                                                    <FormFeedback className="w-100">{touched.city && errors.city}</FormFeedback>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                         <ShowGMap selectedOrder={formData} updateLoctaionCord={updateLoctaionCord} />
                                       
 
                                    </Row>
                                </Col>

                                <Col md="6" className="d-none d-md-block mb-3 align-self-center">
                                    <img src={sideImage} alt="" className="img-fluid" />
                                </Col>
                                <Col sm="12" className="text-center">
                                    <Button
                                        className="RequestBtn px-4 mb-2 font-weight-bolder"
                                        onClick={handleSubmit}>
                                          {t('Update')} 
                                        </Button>
                                 
                                </Col>
                                 {message !== "" &&
                            <Col sm="12" className="order-4">
                                <p className="m-0 text-muted text-center text-dark-green">
                                    {
                                        message
                                    }
                                  
                                </p>
                            </Col>}
                            </Row>
                        </Container>
                    )
                }
            </Formik>
            </>
            )

}



export default JoinPage2;

