import React, { Component } from "react";
import { Post, Get } from "../../../utilities/axiosRequest";
import { convertDate } from "../../Components/Common/Common";
import ShowModalData from "./ShowModalData";
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Table,
    Badge,
    PaginationItem,
    PaginationLink,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ToastBox from "../../Components/ToastBox/ToastBox";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import Pagination from "react-js-pagination";
import axios from 'axios';
const device = [
    { value: "Mobile", label: "Mobile" }
];
const filter = [
    { label: "Country" },
    { label: "City" },
    { label: "Category" },
    { label: "Company Name" }
]

class AdminPage extends Component {

    state = {
        isShowFilterModal: false,
        filteredBy: "",
        filteredBy1: "",
        selectedRecordInfo: null,
        deviceValue: 1,
        startingDate: "",
        endingDate: "",
        sDate: "",
        eDate: "",
        customerId: "",
        deviceId: "",
        customerName: "",
        mobileNumber: "",
        maintenanceRecords: [],
        device: [],
        activePage: 1,
        page: 1,
        itemsCountPerPag: 1,
        totalItemsCount: 1,
        country_name: '',
        city_name: '',
        companylists: [],
        show: false,
        message: '',


    }
    setShow = (status) => {
        this.setState({ show: status });

    }

    dateChangeHandler = (date, name) => {

        this.setState({ [name]: date });

    }
    valueChangeHandler = ({ target: { value, name } }) => {
        console.log(name, value)
        this.setState({ [name]: value })

    }
    infoHandler = (selectedInfo) => {
        this.setState({ selectedRecordInfo: selectedInfo })
    }
    modalTogglerHandler = () => {
        this.setState({ selectedRecordInfo: null });
    }

    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber });
        this.setState({ page: pageNumber });
        axios.get('https://api.mithakh.com/api/v1/store/maintanancereports', {
            params: this.state
        }, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(resp => {
            console.log("Response pagination", resp.data.fixinginfo.data)
            this.setState({ maintenanceRecords: resp.data.fixinginfo.data, totalItemsCount: resp.data.total });

        })


    }

    searchRecords = () => {
        const { startingDate, endingDate, deviceValue, customerId, customerName, deviceId, mobileNumber } = this.state;
        console.log("States..", this.state)
        axios.get('https://api.mithakh.com/api/v1/store/maintanancereports', {
            params: this.state
        }, { headers: { 'Access-Control-Allow-Origin': '*' } }).then(resp => {
            console.log("Fixing info", resp);
            this.setState({ maintenanceRecords: resp.data.fixinginfo.data, totalItemsCount: resp.data.total });

        }

        );
    }

    changeCompanyStatus = (selectedItem) => {
        const dataArr = {
            store_user_id: selectedItem.store_user_id,
            store_status: selectedItem.user_status,
            user_type_id: selectedItem.user_type_id,
            company_id: selectedItem.id,
        }



        Post(`https://api.mithakh.com/api/v1/store/changestatus`, dataArr).then(result => {

            console.log("Message...", result)
            this.setState({ show: true })
            this.setState({ message: result.message })
        })


    }
    componentDidMount() {
        const status = [];

        const service = [];
        const store_id = localStorage.getItem("store_user_id");
        /**Get(`https://api.mithakh.com/api/v1/store/more-info?&store_id=${store_id}`).then(result => {
            if(result.storeinfo.length>0){
            this.setState({country_name:result.storeinfo[0].country_name,city_name:result.storeinfo[0].city_name});
            }
        })**/


        const default_city = 1;
        Get(`https://api.mithakh.com/api/v1/services?&city_id=${default_city}`).then(result => {
            if (result.cityservices) result.cityservices.forEach(function (data, idx) {
                service.push({ 'value': data.service_id, 'label': data.service_title });
            });
            this.setState({ device: service });
            console.log("Services...", result.cityservices)
        });


        // Get(`https://api.mithakh.com/api/v1/store/fixing-status`).then(result => this.setState({fixingstatus:result.fixingstatus})); 
        Get("https://api.mithakh.com/api/v1/store/fixing-status").then(result => {
            console.log("fixing status..", result); result.fixingstatus.forEach(function (data, idx) {
                status.push({ 'value': data.req_status_id, 'label': data.title });
            });

            this.setState({ fixingstatus: status });
        });

        Get(`https://api.mithakh.com/api/v1/store/lists`).then(result => {

            this.setState({ companylists: result.storelists });
        })


    }

    render() {
        const {
            deviceValue,
            maintenanceRecords,
            selectedRecordInfo,
            startingDate,
            endingDate,
            customerId,
            deviceId,
            customerName,
            filteredBy,
            filteredBy1,
            mobileNumber,
            country_name,
            city_name,
            isShowFilterModal, device, totalItemsCount, companylists, message, show } = this.state;

        console.log("state in....maintanance report", companylists)

        return (
            <>
                <ToastBox show={show} message={message} setShow={this.setShow} />
                {
                    selectedRecordInfo ?
                        <Modal isOpen={selectedRecordInfo ? true : false} backdrop="static" className="modalStyle">
                            <ModalHeader toggle={this.modalTogglerHandler}>Device Information</ModalHeader>
                            <ModalBody>
                                <ShowModalData selectedRecord={selectedRecordInfo} />
                            </ModalBody>
                        </Modal> : null
                }
                <Modal isOpen={isShowFilterModal} className="filterModalStyle" backdrop="static">
                    <ModalHeader toggle={() => this.setState({ isShowFilterModal: false })}>Filtered By</ModalHeader>
                    <ModalBody >
                        <Row>
                            {filter.map(item => (
                                <Col key={item.label} xs="12" sm="6" md="6" className="d-flex align-items-center justify-content-center">
                                    <CustomCheckBoxLabel
                                        label={item.label}
                                        active={filteredBy}
                                        groupName="filteredBy"
                                        extraStyle="mx-2 flex-grow-1"
                                        onChange={this.valueChangeHandler}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </ModalBody>
                </Modal>
                {/*<div className="d-flex d-md-none mb-4 justify-content-center">
                    <div className="d-flex align-items-center BoxShadow px-2" onClick={() => this.setState({ isShowFilterModal: true })} style={{ width: 'fit-content', borderRadius: 50 }}>
                        <i className="fa fa-sliders mx-1"></i>
                        <span className="mx-1">Filters</span>
                        <Badge color="warning" className="mx-1 rounded-circle text-light">3</Badge>
                    </div>
                </div>*/}
                <Col md="10" xs="12" className="mb-2">
                    <Row className="mt-1 mb-3">

                        {/*  <Col xs="12" className="d-none mb-2 d-md-flex justify-content-center">
                            {filter.map(item => (
                                <CustomCheckBoxLabel
                                    key={item.label}
                                    label={item.label}
                                    active={filteredBy1}
                                    groupName="filteredBy1"
                                    extraStyle="mx-2"
                                    onChange={this.valueChangeHandler}
                                />
                            ))}
                        </Col>
                        <Col md="6" className="mb-2">
                            <Row>
                                <Col sm="6" className="mb-2">
                                    <Row>
                                        <Col xs='3'>
                                            <span className="font-weight-bold">From</span>
                                        </Col>
                                        <Col xs='9'>
                                            <DatePicker
                                                selected={startingDate}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => this.dateChangeHandler(date, "startingDate")}
                                                placeholderText="Select Date..."
                                                className="BoxShadow px-2 datePicker-1" />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm="6" className="mb-2">
                                    <Row>
                                        <Col xs='3'>
                                            <span className="font-weight-bold">To</span>
                                        </Col>
                                        <Col xs='9'>
                                            <DatePicker
                                                selected={endingDate}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => this.dateChangeHandler(date, "endingDate")}
                                                placeholderText="Select Date..."
                                                className="BoxShadow px-2 datePicker-1" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Input
                                        className="BoxShadow mb-2"
                                        type="text"
                                        value={deviceId}
                                        onChange={this.valueChangeHandler}
                                        placeholder="Request Id"
                                        name="deviceId" />
                                </Col>
                                <Col sm="6">
                                    <Input
                                        type="text"
                                        name="customerName"
                                        value={customerName}
                                        onChange={this.valueChangeHandler}
                                        placeholder="Customer Name"
                                        className="BoxShadow" />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6" className="mb-3">
                            <Row>
                                <Col sm="6">
                                 <Select
                                        className="BoxShadow mb-2"
                                        value={device.find(p => p.value === deviceValue)}
                                        onChange={(value) => this.setState({ deviceValue: value.value })}
                                        placeholder="All Device..."
                                        name="device"
                                        options={device} />
                            
                                </Col>
                                <Col sm="6" className="mb-2">
                                    <Input
                                        type="text"
                                        value={customerId}
                                        name="customerId"
                                        onChange={this.valueChangeHandler}
                                        placeholder="Customer Id"
                                        className="BoxShadow" />
                                </Col>
                                <Col sm="12" className="mb-2">
                                    <Input
                                        name="mobileNumber"
                                        value={mobileNumber}
                                        onChange={this.valueChangeHandler}
                                        type="text"
                                        placeholder="Mobile Number"
                                        className="BoxShadow" />
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" className="text-center mb-3">
                            <Button
                                className="RequestBtn px-4 font-weight-bolder"
                                onClick={this.searchRecords}>Search</Button>
                        </Col>
                        */}
                    </Row>



                    <div className="mb-4">
                        <Table className="mb-2 table-responsive-md" style={{ borderBottom: "1px solid #ff5900" }}>
                            <thead>
                                <tr style={{ backgroundColor: "#FF5900", color: "white" }}>
                                    <th>Store Id</th>
                                    <th>Name</th>
                                    <th>Company/Ind</th>
                                    <th>Mobile No.</th>
                                    <th>Spnsor Name.</th>
                                    <th>Spnsor Mobile.</th>
                                    <th>Country.</th>
                                    <th>City</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    companylists.map((p, ind) => (
                                        <tr key={ind} >
                                            <td>{p.store_user_id}</td>
                                            <td>{p.person_name}</td>
                                            <td>{p.company_or_individual == 1 ? 'Company' : 'Individual'}</td>

                                            <td>{p.mobile_no}</td>
                                            <td>{p.sponsor_name}</td>
                                            <td>{p.sponsor_mobile}</td>
                                            <td>{p.country_name}</td>
                                            <td>{p.city_name}</td>
                                            <td><a href="#" onClick={() => this.changeCompanyStatus(p)} >{p.user_status == 1 ? <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-times" aria-hidden="true"></i>}</a></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this.handlePageChange.bind(this)}
                        />



                        {/** <Pagination size="sm">
                            <PaginationItem className="ml-auto">
                                <PaginationLink>
                                    Previous
                        </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink>
                                    Next
                        </PaginationLink>
                            </PaginationItem>
                            <PaginationItem active>
                                <PaginationLink>
                                    1
                        </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink>
                                    2
                        </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                        **/}
                    </div>
                </Col>
            </>
        );
    }
}

export default AdminPage;