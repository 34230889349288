import React from 'react';
import { Row, Col, Input, Button, FormGroup ,FormFeedback} from "reactstrap";
import Select from "react-select";
import sideImage from "../../../assets/images/Group 426.png";
import classnames from "classnames";
import GoogleMap from '../../Components/GoogleMap';
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import NavTabs from "../../Components/Tab/TabPanel";
import RequestDetails from "../../Pages/CompanyPages/RequestDetails";
import { Formik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
    country: Yup.string()
        .required("Country is Required"),
    city: Yup.string()
        .required("City is Required"),
    mobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .min(1000000000, "Enter valid mobile number")
        .max(9999999999, "Enter valid mobile number")
        .required("Mobile number is required"),
    deviceModel: Yup.string()
        .min(3, "Enter valid device number")
        .required("Device Model is required")
});
const country = [
    {
        value: "India",
        label: "India"
    }
];
const RequestInfo = ({ selectedOrder, changeComponent,fixingstatus,collectstatus,checkBoxChangeHandler,saveRequestDetails,updateLoctaionCord,showModal,togglePop,assignRequestTo,assignTo,showlocation,modalTogglerHandler,infoLocation,show,message,setShow,denyRequest,trackMobile,askDriver}) => {
    const submitHandler = (values, resetForm) => {
        selectedOrder(values);
        console.log(values);
        changeComponent("gettingInfo2");
    };
    return (
    <Formik
            initialValues={{
                country: "",
                city: "",
                mobileNumber: "",
                deviceModel: ""
            }}
            validationSchema={validationSchema}
            onSubmit={
                (values, { resetForm }) => submitHandler(values, resetForm)
            }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
            }) => (
                    <>
                <Row className="mt-2">
                    <Col md="12">
                        {/**<NavTabs changeComponent={changeComponent} saveRequestDetails ={saveRequestDetails} fixingstatus ={fixingstatus} selectedOrder={selectedOrder}  checkBoxChangeHandler={checkBoxChangeHandler} showModal={showModal} togglePop={togglePop} assignRequestTo={assignRequestTo} assignTo={assignTo} showlocation={showlocation} modalTogglerHandler={modalTogglerHandler} infoLocation={infoLocation} updateLoctaionCord={updateLoctaionCord} show={show} message={message} setShow={setShow} denyRequest={denyRequest} />*/}
                        <RequestDetails changeComponent={changeComponent} saveRequestDetails={saveRequestDetails} fixingstatus ={fixingstatus} collectstatus={collectstatus} selectedOrder={selectedOrder}  checkBoxChangeHandler={checkBoxChangeHandler} showModal={showModal} togglePop={togglePop} showlocation={showlocation} modalTogglerHandler={modalTogglerHandler} infoLocation={infoLocation} updateLoctaionCord={updateLoctaionCord}  show={show} message={message} setShow={setShow} denyRequest={denyRequest} trackMobile={trackMobile} askDriver={askDriver} />
                
        
                       
                    </Col>
                   
                </Row>
      </>
                )}
        </Formik>)

};

export default RequestInfo;
