
export const maintenanceData = [
    {
        deviceId: "738AS",
        deviceName: "Lunar Epic",
        mobileNo: 123456789,
        status: "Arrived",
        deviceType: "Mobile",
        Ended: "Yes",
        location: "Yes",
        deviceFixed: "Yes",
        explanation: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem voluptatem corporis corrupti!"
    },
    {
        deviceId: "73123AS",
        deviceName: "laskj",
        mobileNo: 123456789,
        status: "Arrived",
        deviceType: "Mobile",
        Ended: "Yes",
        location: "Yes",
        deviceFixed: "Yes",
        explanation: "Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem voluptatem corporis corrupti!"
    },
    {
        deviceId: "734AS",
        deviceName: "kwkjkbsd",
        mobileNo: 123456789,
        status: "Pending",
        deviceType: "Mobile",
        Ended: "Yes",
        location: "Yes",
        deviceFixed: "Yes",
        explanation: "Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        deviceId: "734AS",
        deviceName: "oiuaon",
        mobileNo: 123456789,
        status: "Arrived",
        deviceType: "Mobile",
        Ended: "Yes",
        location: "Yes",
        deviceFixed: "Yes",
        explanation: "Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        deviceId: "734AS",
        deviceName: "kwkjkbsd",
        mobileNo: 123456789,
        status: "Pending",
        deviceType: "Mobile",
        Ended: "Yes",
        location: "Yes",
        deviceFixed: "Yes",
        explanation: "Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit"
    },
    {
        deviceId: "734AS",
        deviceName: "oiuaon",
        mobileNo: 123456789,
        status: "Arrived",
        deviceType: "Mobile",
        Ended: "Yes",
        location: "Yes",
        deviceFixed: "Yes",
        explanation: "Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit"
    }
];