import React from 'react';
import { Row, Col, Input, Button, FormGroup ,FormFeedback} from "reactstrap";
import Select from "react-select";
import sideImage from "../../../assets/images/Group 426.png";
import classnames from "classnames";
import GoogleMap from '../../Components/GoogleMap';
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import NavTabs from "../../Components/Tab/TabPanel";
import { Formik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
    country: Yup.string()
        .required("Country is Required"),
    city: Yup.string()
        .required("City is Required"),
    mobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .min(1000000000, "Enter valid mobile number")
        .max(9999999999, "Enter valid mobile number")
        .required("Mobile number is required"),
    deviceModel: Yup.string()
        .min(3, "Enter valid device number")
        .required("Device Model is required")
});
const country = [
    {
        value: "India",
        label: "India"
    }
];
const CustomerContact = ({ selectedOrder, changeComponent,checkBoxChangeHandler ,assignRequestTo,assignTo}) => {
     const submitHandler = (values, resetForm) => {
       
        changeComponent("gettingInfo2");
        // resetForm({ values: "" });
    };
    return (
    <Formik
            initialValues={{
               
            }}
            validationSchema={validationSchema}
            onSubmit={
                (values, { resetForm }) => submitHandler(values, resetForm)
            }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
            }) => (
                    <>
                <Row className="mt-2">
                    <Col md="12">
                                                              
                       <Row className="mb-3">
                            <Col sm="12" className="align-self-center font-weight-bold">
                                How to send to customer
                            </Col>
                           
                        </Row>
                        <Row className="mb-3 d-flex">
                        <Col xs="12" sm="12" className="align-self-center font-weight-bold">
                            <div className="text-center d-inline-block " >
                                <Button className={assignTo=="technition" ? 'assignBtns' : '' } onClick={() => assignRequestTo("technition")}>
                                   Technition
                                </Button>
                            </div>
                             <div className="text-center d-inline-block mx-2">
                                <Button className={assignTo=="companydriver" ? 'assignBtns' : '' } onClick={() => assignRequestTo("companydriver")}>
                                    Company Driver
                                </Button>
                            </div>
                        </Col>
                        
                         <Col xs="12" sm="12" className="align-self-center font-weight-bold next-row">
                            <div className="text-center d-inline-block ">
                                <Button className={assignTo=="outsidedriver" ? 'assignBtns' : '' } onClick={() => assignRequestTo("outsidedriver")}>
                                    Outside Driver
                                </Button>
                            </div>
                             <div className="text-center d-inline-block mx-2">
                                <Button className={assignTo=="otherway" ? 'assignBtns' : '' } onClick={() => assignRequestTo("otherway")}>
                                   Other Way
                                </Button>
                            </div>
                        </Col>
                        
                    </Row>
        		   <Row className="mb-3" className="apply-store-btns">
                            <Col sm="3" xs="6" className="align-self-center font-weight-bold">
                            
                             <div className="text-center1">
                               
                                <Button
                                className="AssignBtn px-4 mb-2 font-weight-bolder"
                                onClick={handleSubmit}>
                               Assign
                            </Button>

                              
                            </div>
                           </Col>
                           
                    </Row>   
                       
                      
                       
                    </Col>
                   
                </Row>
      </>
                )}
        </Formik>)

};

export default CustomerContact;
