import React from 'react';
import { Row, Col, Input, Button } from "reactstrap";
import sideImage from "../../../assets/images/Group 426.png";
//import GoogleMap from '../../Components/GoogleMap';
import ShowGMap from '../../Components/ShowGMap';
import ToastBox from "../../Components/ToastBox/ToastBox";
import Loader from 'react-loader-spinner';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const CompanyAccept = ({ selectedOrder, updateLoctaionCord,changeOrderStatus,spinnerLoading,show,message,setShow ,distance }) => {
console.log("Selected order",selectedOrder)
     const { t, i18n } = useTranslation();
    return (
        <>
        <ToastBox show={show} message={message} setShow={setShow} />  
        <Row className="mt-4">
         
            <Col md="6">
        
                <Row className="mb-3">
                    <Col xs="12" sm="12" className="align-self-center font-weight-bold d-flex">
						<span className=" d-inline-block" style={{ minWidth: "93px" }}>{t('Request Id')} </span>:
                        <span className="mx-2">{selectedOrder.request_id}</span>
                    </Col>
                    
                </Row>
				
				<Row className="mb-3">
                    <Col xs="12" sm="12" className="align-self-center font-weight-bold d-flex">
						<span className=" d-inline-block" style={{ minWidth: "93px" }}>{t('Device type')}</span>:
                        <span className="mx-2">{i18next.language=="en" ? selectedOrder.service_title : selectedOrder.service_title_ar }</span>
						<span className="mx-1">
                       
                        {i18next.language=="en" ? <i class="fa fa-angle-right" aria-hidden="true"></i>: <i class="fa fa-angle-left" aria-hidden="true"></i> }

                        </span>
						<span className="mx-2"> {i18next.language=="en" ? selectedOrder.task_title : selectedOrder.task_title_ar }</span>
						
						
                    </Col>
                    
                </Row>

                <Row className="mb-3">
                    <Col xs="12" sm="12" className="align-self-center font-weight-bold d-flex">
                        <span className=" d-inline-block" style={{ minWidth: "93px" }}>{t('Device Modal') }</span>
                        <span className="mx-2">
                            <Input
                                   placeholder={t('Device Modal') }
                                   name="deviceModel"
                                   disabled
                                    type="text"
                                   value={selectedOrder.device_model}
                                    className="BoxShadow mb-2" />
                        
                        </span>
                        
                    </Col>
                    
                </Row>
				<Row className="mb-3">
                    <Col xs="12" sm="12" className="align-self-center font-weight-bold d-flex">
						<span className=" d-inline-block" style={{ minWidth: "93px" }}>{t('Clarify') }</span>
                        <span className="mx-2">
						    <Input
                                     placeholder={t('Clarify') }
                                    
                                   
                                    name="clarify"
                                    type="textarea"
                                   value={selectedOrder.explain_fix !="" ? selectedOrder.explain_fix : selectedOrder.error_explain}
                                    className="BoxShadow mb-2" />
						
						</span>
						
                    </Col>
                    
                </Row>
				<Row className="mb-12">
                   <ShowGMap selectedOrder={selectedOrder} updateLoctaionCord={updateLoctaionCord} />
                </Row>

                <Row className="mb-3 mt-3">
                    <Col xs="12" sm="12" className="align-self-center font-weight-bold">
                      <span style={{ color: "red" }}>Distance in KM : {distance}</span>
                        
                    </Col>
                    
                </Row>

                <Row className="mb-3" className="apply-store-btns d-flex">
                    <Col sm="6" xs="6" className="align-self-center font-weight-bold">
                    
                     <div className="text-center">
                       
                     <Button
                        className="acceptBtn px-4 mb-2"
                         onClick={() => changeOrderStatus(1)}>
                         {t('Accept') }
                    </Button>

                      
                    </div>
                   </Col>
                    <Col sm="6" xs="6"  className="align-self-center font-weight-bold d-flex"> 
                    
                     <div className="text-center">
                            <Button className="denyBtn"
                            onClick={() => changeOrderStatus(10)}>
                                {t('Deny') } 
                            </Button>
                       </div>
                   
                    </Col>
                    <Col sm="12" className="text-center mt-3 order-3 mb-2">
                             <Loader
                             type="ThreeDots"
                             color="#00BFFF"
                             height={100}
                             width={100}
                             timeout={2000} 
                             visible={spinnerLoading}
                            />
                   </Col>            
                </Row>
             
            </Col>
           
        </Row>
    </>
        
    )  
};

export default CompanyAccept;
