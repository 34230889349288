import React from 'react';
import { Link,useHistory,NavLink } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';


import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart } from 'react-icons/fa';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import sidebarBg from '../../../assets/images/bg1.jpg';
import { useCookies } from "react-cookie";
const AsideDriver = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
   const { t, i18n } = useTranslation();
   const [cookies, setCookie, removeCookie] = useCookies(["customer"]);
 const history = useHistory();
    const signOutHandler = () => {
        localStorage.clear();
         removeCookie("customer");
        history.push("/")
        window.location.reload();
    }

    const acceptedRequest=(status)=>{
      console.log("sasasa",window.location.href)
      
      var full = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
       var newurl= full+'/driver/pending-requests/'+status;
     
     window.location.href=""+newurl;
     
    }

     const pendingRequest=()=>{
     var full = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
      var newurl= full+'/driver-home';
     
     window.location.href=""+newurl;
     
    }
   const profilename=localStorage.getItem("firstname");
  return (
    <ProSidebar
     
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
           
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
         { t('Driver Profile')} - {profilename }
        
        </div>
      </SidebarHeader>

      <SidebarContent>

        <Menu iconShape="circle">
        

           <MenuItem icon={<FaGem />}>
        
            <NavLink  to="/" exact activeClassName="selected">  { t('Home') }</NavLink>
        
         </MenuItem>


          <MenuItem
            icon={<FaTachometerAlt />}
            suffix={<span className="badge red">new</span>}
          >
        
          <NavLink onClick={()=>pendingRequest(1)} to="/driver-home" exact activeClassName="selected">  { t('Pending Request') }</NavLink>
          </MenuItem>
           <MenuItem icon={<FaGem />}> 
            <NavLink  onClick={()=>acceptedRequest(1)} to="/driver/pending-requests/1" exact activeClassName="selected">   { t('Accepted Requests') }</NavLink>
          
        </MenuItem>
        <MenuItem icon={<FaGem />}>
          
           <NavLink  to="/driver/pending-requests/8" exact activeClassName="selected">  { t('Finished Requests') }</NavLink>
         </MenuItem>
        
           <SubMenu
          
            title= { t('Settings') }
            icon={<FaRegLaughWink />}
          >
            <MenuItem  icon={<FaGem />}>
           
              <NavLink to="/driver/profile/1"   exact activeClassName="selected"> { t('Change Pofile') }</NavLink>
          
            </MenuItem>
         
          </SubMenu>

         <MenuItem icon={<FaGem />} >{ t('Logout') } 
          <Link onClick={signOutHandler}/>
        </MenuItem>

		   
        </Menu>
		
      </SidebarContent>

     
    </ProSidebar>
  );
};

export default AsideDriver;
