import React from 'react';
import { Row, Col, Input, Button, FormGroup ,FormFeedback, Modal, ModalBody,ModalHeader} from "reactstrap";
import Select from "react-select";
import sideImage from "../../../assets/images/Group 426.png";
import classnames from "classnames";
import GoogleMap from '../../Components/GoogleMap';
import PopUp from '../../Components/Modal/ModalComponent';
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import LocationModalData from "./LocationModalData";
import ShowImageModalData from "./ShowImageModalData";
import ToastBox from "../../Components/ToastBox/ToastBox";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { Formik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
   fixingstatus: Yup.string()
    .required("Fixing status is required")
});
const country = [
    {
        value: "India",
        label: "India"
    }
];
 const download = e => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
const RequestDetails = ({ selectedOrder, changeComponent,fixingstatus,checkBoxChangeHandler,saveRequestDetails,showModal, togglePop ,showlocation,modalTogglerHandler,infoLocation,updateLoctaionCord,spinnerLoading,show,message,setShow,denyRequest,trackMobile}) => {
 const { t, i18n } = useTranslation();

     const submitHandler = (values, resetForm) => {
       // selectedOrder(values);
      // console.log("Values....",values)
        saveRequestDetails(values);
    };
    return (

    <Formik
            initialValues={{
                
               fixingstatus:selectedOrder.driver_device_status_id,
               request_id:selectedOrder.request_id,
               clarify:selectedOrder.error_explain,
               explain_fix :'',
               explain_visit:'',
               visit_amount:selectedOrder.visit_amount,
               explanation_visit :selectedOrder.explanation_visit!="" ? selectedOrder.explanation_visit : '',

            }}
            validationSchema={validationSchema}
            onSubmit={
                (values, { resetForm }) => submitHandler(values, resetForm)

            }>
            {({
                errors,
                values,
                touched,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit
            }) => (
                    <>

                    {
                   showlocation ?
                        <Modal isOpen={showlocation ? true : false} backdrop="static" className="modalStyle">
                            <ModalHeader toggle={modalTogglerHandler}>Location Details</ModalHeader>
                            <ModalBody>
                                <LocationModalData selectedOrder={selectedOrder} updateLoctaionCord={updateLoctaionCord}/>
                            </ModalBody>
                        </Modal> : null
                    }
                    {
                    showModal ? 
                        <Modal isOpen={ showModal ? true : false} backdrop="static" className="modalStyle">
                            <ModalHeader toggle={togglePop}>Location Details</ModalHeader>
                            <ModalBody>
                                  <ShowImageModalData showModal={showModal}  img_link={selectedOrder.upload_link} />
                            </ModalBody>
                        </Modal> : null

                  

                 }
                <Row>
                     <ToastBox show={show} message={message} setShow={setShow} /> 
                </Row> 
                <Row className="mt-2">
                    <Col md="12" xs="12" >
                    
                        <div className="top-row-request-info BoxShadow mb-3 top-boxer">
                        <Row className="mb-1">
                            <Col xs="3" sm="2" className="align-self-center top-col-left d-flex mr-3">
        					         	<span className=" d-inline-block" >{selectedOrder.request_id}</span>:
                              
                                 <a href="#"  onClick={() => infoLocation(selectedOrder)}><span className="mx-2 d-inline-block"  ><i class="fa fa-map-marker" aria-hidden="true"></i> </span></a>
                           
                            </Col>

                             <Col xs="5" sm="6" className="align-self-center top-col-left d-flex mr-3 pl-0">

                                  <span className="mx-1" style={{ fontSize: 'xx-medium' }}>
                                           {selectedOrder.req_status_id=="1" ? <a onClick={()=>trackMobile(selectedOrder,1)} ><p class="call-button"><i class="fa fa-phone-square" aria-hidden="true"></i></p></a> : <i class="fa fa-phone-square" aria-hidden="true"></i> }
                                      
                                        </span>
                                   <span className="mx-1" style={{ fontSize: 'xx-medium' }}>

                                       {i18next.language=="en" ? selectedOrder.service_title :selectedOrder.service_title_ar }
                                         <label className="mr-1">
                                         {i18next.language=="en" ? <i class="fa fa-angle-right" aria-hidden="true"></i>: <i class="fa fa-angle-left" aria-hidden="true"></i>} {i18next.language=="en" ? selectedOrder.task_title :selectedOrder.task_title_ar }</label>
                                    </span>
                                     <span className="mx-1" style={{ fontSize: 'xx-medium' }}>
                                          <a onClick={()=>trackMobile(selectedOrder,2)}  style={{ fontSize: 'xx-medium',color:'green' }}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                     </span>
                             </Col>
                            
                        
                            
                        </Row>

                        <Row className="mb-1">
                           
                           
                             <Col  xs="12" sm="6" className="align-self-center">
                              <div className="d-block d-flex" style={{alignItems: "center",justifyContent:"center" }} >
                              <span  className=" d-inline-block" style={{ minWidth: "55px" ,lineHeight:"3em",fontSize:"14px"}}>{t('Device Status')} :</span>
                               <span class="mx-2 mt-2" style={{ minWidth: "200px" }} >
                                 <FormGroup>
                                                <Select
                                                    invalid={errors.fixingstatus && touched.fixingstatus}
                                                    className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.fixingstatus && errors.fixingstatus })}
                                                    value={fixingstatus.find(p => p.value === values.fixingstatus) || null}
                                                    onChange={(value) => setFieldValue("fixingstatus", value.value)}
                                                    placeholder={t('Device Status')}
                                                    onBlur={handleBlur("fixingstatus")}
                                                    name="fixingstatus"
                                                    options={fixingstatus} />
                                                <FormFeedback> {touched.fixingstatus && errors.fixingstatus}</FormFeedback>
                                            </FormGroup>

                              </span>
                               </div>
                            </Col>
                        
                          
                        </Row>
                        </div>
                        <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                              {t('Full Name')} 
                            </Col>
                            <Col sm="3">
                                <Input className="BoxShadow" value={selectedOrder.customer_name} type="text" disabled />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                                  {t('Mobile Number')}
                            </Col>
                            <Col sm="3">
                                <Input className="BoxShadow" type="text" value={selectedOrder.customer_mob}  disabled />
                            </Col>
                        </Row>

                         <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                                  {t('Where Device')}
                            </Col>
                            <Col sm="3">
                                <Input className="BoxShadow" type="text" value={selectedOrder.device_model}  placeholder={t('Where Device')}  disabled />
                            </Col>
                        </Row>

                         <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                               {t('Clarify')} 
                            </Col>
                            <Col sm="3">
                                <FormGroup>
                                <Input
                                    invalid={errors.clarify && touched.clarify}
                                    placeholder={t('Clarify')} 
                                    value={selectedOrder.error_explain}
                                    onChange={handleChange("clarify")}
                                    name="clarify"
                                    type="textarea"
                                    onBlur={handleBlur("clarify")}
                                    className="BoxShadow mb-2" />
                                <FormFeedback>{touched.clarify && errors.clarify}</FormFeedback>
                            </FormGroup>
                            </Col>
                            {/* <Col sm="2" className="align-self-center font-weight-bold ">
                            <div className="btn" onClick={togglePop}>
                              <button>{t('Attachments')}</button>
                            </div>
                          
                              
                              
                            </Col>**/}
                        </Row>


                         
                         

                        <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                                  {t('Visit Cost')}
                            </Col>
                            <Col sm="3">
                                <Input className="BoxShadow" type="text"  name="visit_amount" 
                                                            value={values.visit_amount}
                                                            name="visit_amount"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                             placeholder= {t('Visit Cost')}   />
                            </Col>
                        </Row>
                         
                        <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                                 {t('Explain Visit')} 
                            </Col>
                            <Col sm="3">
                              <FormGroup>
                                <Input
                                    invalid={errors.explanation_visit   && touched.explanation_visit  }
                                    placeholder= {t('Explain Visit')}
                                    value={values.explanation_visit }
                                    onChange={handleChange("explanation_visit")}
                                    name="explanation_visit"
                                    type="textarea"
                                    maxlength="90"
                                    onBlur={handleBlur("explanation_visit")}
                                    className="BoxShadow mb-2" />
                                <FormFeedback>{touched.explanation_visit   && errors.explanation_visit  }</FormFeedback>
                            </FormGroup>
                            </Col>
                        </Row>
                

                      
        			
                       
                        <Row className="mb-3" className="apply-store-btns">

                             <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                                <Input className="BoxShadow" type="hidden" name="request_id" value={selectedOrder.request_id} disabled />
                            </Col>
                            <Col sm="3" xs="6" className="align-self-center font-weight-bold">
                            
                             <div className="text-center d-flex">
                               
                                <Button
                                className="acceptBtn px-4 mb-2 font-weight-bolder"
                                onClick={handleSubmit}>
                                {t('Save')}
                            </Button>
                             {/*<Button
                                className="denyBtn px-4 mb-2 font-weight-bolder mr-3"
                                onClick={() => denyRequest(selectedOrder.request_id)}>
                                     {t('Deny')}
                                 </Button>*/}

                              
                            </div>

                           
                           </Col>
                           
                            
                        </Row>
                       
                        <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                            {selectedOrder.driver_status==1 ?   t('Go to customer') : t('Go to Company')} 
                            </Col>
                            <Col sm="3">
                            {selectedOrder.driver_status==1 ?
                               <a  target="_blank" href={"https://www.google.com/maps/@"+selectedOrder.req_lat+","+selectedOrder.req_long}>
                                <i class="fa fa-map-marker"  aria-hidden="true"></i>
                               </a>
                              :
                              <a  target="_blank" href={"https://www.google.com/maps/@"+selectedOrder.store_lat+","+selectedOrder.store_long}>
                                <i class="fa fa-map-marker"  aria-hidden="true"></i>
                               </a>
                               }
                            </Col>
                        </Row>

                          <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                              {t('Company Explanation')} 
                            </Col>
                            <Col sm="3">
                                <Input className="BoxShadow" value={selectedOrder.person_name} type="text" disabled />
                            </Col>
                        </Row>

                       <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                              {t('Company Name')} 
                            </Col>
                            <Col sm="3">
                                <Input className="BoxShadow" value={selectedOrder.person_name} type="text" disabled />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm="2" className="align-self-center font-weight-bold accepted-form d-flex">
                                  {t('Mobile Number')}
                            </Col>
                            <Col sm="3">
                                <Input className="BoxShadow" type="text" value={selectedOrder.person_mobile}  disabled />
                            </Col>
                        </Row>
                       
                       
                    </Col>
                   
                </Row>
      </>
                )}
        </Formik>)

};

export default RequestDetails;
