import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translationEN from './utilities/locales/en/translation.json';
import translationEN1 from './utilities/locales/en/translation1.json';
import translationaAR from './utilities/locales/ar/translation.json';
import translationaFR from './utilities/locales/fr/translation.json';
// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationaAR
  },
  fr: {
    translation: translationaFR
  }


  /**en: {
   translation: {
     "Signin": "Sign in"
   }
 },
 ar: {
   translation: {
     "Signin": "يسي يسي"
   }
 }
 **/

};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ar',
    fallbackLng: ["en", "ar", "fr"], // use en if detected lng is not available
    // fallbackLng: 'ar',
    // debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: false,
    }
  });

export default i18n;