import React from "react";
import {
  FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon,

  EmailShareButton, EmailIcon, TwitterShareButton, TwitterIcon

} from "react-share";

export default function SocialMediaButtons(props) {

  return (
    <>
      <div className="share-col-icon">
        <FacebookShareButton
          url={"https://mithakh.com"}
          quote={props.msg_share}
          hashtag="#camperstribe"
          className="sdsds">
          <FacebookIcon size={36} />
        </FacebookShareButton>
      </div>
      <div className="share-col-icon">
        <WhatsappShareButton
          url={"https://mithakh.com"}
          title={props.msg_share}

          className="m-2">
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>
      <div className="share-col-icon">
        <LinkedinShareButton
          url={"https://mithakh.com"}
          title={props.msg_share}

          className="m-2">
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
      </div>
      <div className="share-col-icon">
        <EmailShareButton
          url={"https://mithakh.com"}
          title={props.msg_share}

          className="Demo__some-network__share-button m-2">
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
      <div className="share-col-icon">
        <TwitterShareButton
          url={"https://mithakh.com"}
          title={props.msg_share}

          className="m-2">
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>
    </>
  );
}