import React, { Component } from "react";
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback,InputGroup } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import CustomCheckBoxLabelPlain from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabelPlain";
import sideImage from "../../../assets/images/Group 426.png";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import GoogleMap from "../../Components/GoogleMap";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const phoneRegExp =/^(9665|\+9665|5|05|\+00966)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(5, "Enter more than 5 characters")
        .max(25, "Allow only 25 characters")
        .required(i18next.lang=="en" ? "Name is required" : "الإسم مطلوب"),

   
   

    mobileNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
         .required(i18next.lang=="en" ? "Mobile number is required" : "رقم الجوال مطلوب"),
        
    

   
    
   
});


const country = [
    {
        value: "India",
        label: "India"
    }
];

const services = [
    { id: "Mobile", name: "Mobile" },
    { id: "Elevator", name: "Elevator" },
    { id: "A/C", name: "A/C" },
    { id: "Electricity", name: "Electricity" },
    { id: "Plumbing ", name: "Plumbing " },
    { id: "Cars", name: "Cars" },
];
const city = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Rajkot", label: "Rajkot" },
    { value: "Surat", label: "Surat" },
];


const EditProfile = ({userInfo,country_code,userDuplicate,showPassword,loginHandler,lat,lng,updateLoctaionCord,filterCities,country,city}) => {

    const { t, i18n } = useTranslation();
   
    let parsedNumber = parseInt(userInfo.customer_mobile1, 10);
   
    const submitHandler = (values, resetForm) => {
      loginHandler(values)
       
    };

    return (
        <Col md="6" className="align-self-center mt-3">
                            <h3 style={{ color: "#FF5900" }}  className={i18next.language=="ar" ? 'text-right pr-1' : '' }>{t('Roving Maintenance')}</h3>
                          
                            <Formik
                                  enableReinitialize={true}
                                    initialValues={{
                                    name:  userInfo.customer_name  ? userInfo.customer_name : '',
                                    mobileNumber: parsedNumber  ? parsedNumber : '',
                                    emailAddress:  userInfo.email  ?  userInfo.email : '',
                                    password: "",
                                    confirmPass: "",
                                    lat: userInfo.loc_lattitude>0 ? userInfo.loc_lattitude : '',
                                    lang: userInfo.loc_longitude>0 ? userInfo.loc_longitude : '',
                                   country_id:  userInfo.country_id >0 ?  userInfo.country_id : '',
                                   city_id:  userInfo.city_id >0  ? userInfo.city_id : '',
                                    username:userInfo.username ? userInfo.username : '',
                                    user_id: userInfo.customer_user_id ? userInfo.customer_user_id: '',
                                    userInfo:userInfo
                                }}
                               validationSchema={validationSchema}
                onSubmit={
                    (values, { resetForm }) => submitHandler(values, resetForm)
                }>
                {({
                   errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit
                }) => (
                                        
                                          
                                            <Row className="mt-1 mb-5">
                                                <Col xs="12" className="">
                                                    <Label className={i18next.language=="ar" ? 'text-right pr-1' : '' } >{t('Name')}</Label>
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.name && touched.name}
                                                            type="text"
                                                            value={values.name}
                                                            name="name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder={t('Full Name')}
                                                            maxlength="30"
                                                            className="BoxShadow pl-4" />
                                                        <FormFeedback>{touched.name && errors.name}</FormFeedback>
                                                    </FormGroup>
                                                </Col>

                                                   <Col xs="12">
                                                    <Row>
                                                        <Col md="12" lg="6">
                                                           <FormGroup>
                                                            <Select
                                                                invalid={errors.country && touched.country}
                                                                className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country_id && errors.country_id })}
                                                                value={country.find(p => p.value === values.country_id) || null}
                                                                onChange={(value) => this.filterCities( value.value,setFieldValue)}

                                                               
                                                                placeholder={t('signup')}
                                                                onBlur={handleBlur("country")}
                                                                name="country_id"
                                                                options={country} />
                                                            <FormFeedback> {touched.country_id && errors.country_id}</FormFeedback>
                                                        </FormGroup>
                                                        </Col>
                                                        <Col md="12" lg="6">
                                                            <FormGroup>
                                                                <div className="d-flex flex-wrap mb-2">
                                                                   <Select
                                                                    className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city_id && errors.city_id })}
                                                                    value={city.find(p => p.value === values.city_id) || null}
                                                                
                                                                    onChange={(value) => setFieldValue("city_id", value.value)}
                                                                    placeholder="Select City..."
                                                                    onBlur={handleBlur("city")}
                                                                    name="city_id"
                                                                    options={city} />
                                                                <FormFeedback className="w-100">{touched.city_id && errors.city_id}</FormFeedback>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                 
                                                  
                                                  
                                                <Col xs="12" className="">
                                                   {/**  <Label className={i18next.language=="ar" ? 'text-right pr-1' : '' } >{t('Mobile Number')}</Label>
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.mobileNumber && touched.mobileNumber}
                                                            type="text"
                                                            value={values.mobileNumber}
                                                            name="mobileNumber"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder={t('Mobile Number')}
                                                            maxlength="14"

                                                            className="BoxShadow pl-4" />
                                                        <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                                    </FormGroup>**/}
                                                    <Row>
                                                      <Col xs="9" md="9" lg="9">
                                                       <div className="d-flex flex-wrap mt-2">
                                                           <FormGroup>
                                                            <Input
                                                                invalid={errors.mobileNumber && touched.mobileNumber}
                                                                placeholder={t('Mobile No')}
                                                                value={values.mobileNumber}
                                                                onChange={(value) => setFieldValue("mobileNumber", value.value)}
                                                                onChange={ev => this.changeArabic(ev, setFieldValue)}
                                                                name="mobileNumber"
                                                                type="text"
                                                                maxlength="10"
                                                                
                                                                onBlur={handleBlur("mobileNumber")}
                                                                className="BoxShadow mb-2 d-block" />
                                                            <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                                             </FormGroup>
                                                        </div>
                                                    </Col>
                                                     <Col xs="3" md="3" lg="3" className="d-flex pt-2 ">
                                                          <span className="country-code">{country_code}+</span>
                                                     </Col>

                                                </Row>
                                                </Col>
                                                 <Col xs="12" >
                                                    <Row>
                                                        <Col md="6" sm="12">
                                                             <Label className={i18next.language=="ar" ? 'text-right pr-1' : '' } >{t('Email Address')}</Label>
                                                            <FormGroup>
                                                                <Input
                                                                    invalid={errors.emailAddress && touched.emailAddress}
                                                                    type="text"
                                                                    value={values.emailAddress}
                                                                    name="emailAddress"
                                                                    onChange={handleChange("emailAddress")}
                                                                    onBlur={handleBlur("emailAddress")}
                                                                    placeholder={t('Email address')}
                                                                    maxlength="75"
                                                                    className="BoxShadow pl-4" />
                                                                <FormFeedback>{touched.emailAddress && errors.emailAddress}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="12" md="6">
                                                             <Label className={i18next.language=="ar" ? 'text-right pr-1' : '' } >{t('Username')}</Label>
                                                            <FormGroup>
                                                                <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.username && touched.username || userDuplicate })}>
                                                                    <Input
                                                                        invalid={errors.username && touched.username}
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        value={values.username}
                                                                        name="username"
                                                                        disabled
                                                                        onChange={handleChange}
                                                                         onBlur={e => {
                                                                                handleBlur(e);
                                                                                this.validateUsername(e.target.value)
                                                                            }}
                                                                          
                                                                        placeholder={t('Username')}
                                                                        maxlength="10"
                                                                        className="pl-4 border-0 shadow-none" />
                                                                 
                                                                </InputGroup>
                                                             <FormFeedback>{touched.username && errors.username}</FormFeedback>
                                                                { userDuplicate && 
                                                                <FormFeedback>Username already taken</FormFeedback>
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                
                                                <Col xs="12" >
                                                    <Row>
                                                        <Col md="6" sm="12">
                                                             <Label className={i18next.language=="ar" ? 'text-right pr-1' : '' } >{t('Password')}</Label>
                                                            <FormGroup>
                                                                <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.password && touched.password })}>
                                                                    <Input
                                                                        invalid={errors.password && touched.password}
                                                                        autoComplete="off"
                                                                        type={showPassword ? "text" : "password"}
                                                                        value={values.password.trim()}
                                                                        name="password"
                                                                        onChange={handleChange("password")}
                                                                        onBlur={handleBlur("password")}
                                                                        placeholder={t('Password')}
                                                                        maxlength="14"
                                                                        className="pl-4 border-0 shadow-none" />
                                                                    <i
                                                                        className={classnames("passwordHideShow d-flex align-items-center pr-4", { "fa fa-eye-slash": !showPassword, "fa fa-eye": showPassword })}
                                                                        onClick={() => this.setState((prev) => ({ showPassword: !prev.showPassword }))}></i>
                                                                </InputGroup>
                                                                <FormFeedback>{touched.password && errors.password}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="12" md="6">
                                                              <Label className={i18next.language=="ar" ? 'text-right pr-1' : '' } >{t('Confirm Password')}</Label>
                                                            <FormGroup>
                                                                <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.confirmPass && touched.confirmPass })}>
                                                                    <Input
                                                                        invalid={errors.confirmPass && touched.confirmPass}
                                                                        autoComplete="off"
                                                                        type={showPassword ? "text" : "password"}
                                                                        value={values.confirmPass.trim()}
                                                                        name="confirmPass"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        placeholder={t('Password')}
                                                                        maxlength="14"
                                                                        className="pl-4 border-0 shadow-none" />
                                                                    <i
                                                                        className={classnames("passwordHideShow d-flex align-items-center pr-4", { "fa fa-eye-slash": !showPassword, "fa fa-eye": showPassword })}
                                                                        onClick={() => this.setState((prev) => ({ showPassword: !prev.showPassword }))}></i>
                                                                </InputGroup>
                                                                <FormFeedback>{touched.confirmPass && errors.confirmPass}</FormFeedback>
                                                            </FormGroup>
                                                            <input type="hidden" name="user_id" id="user_id" value={ userInfo.customer_user_id} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                             
                                                <GoogleMap updateLoctaionCord={updateLoctaionCord }  defaultLat={lat} defaultLang={lng}/>

                                                <Col xs='12' className="mt-4 text-right">
                                                  
                                                </Col>
                                                <Col xs="12" className="text-center mt-3 mb-3">
                                                    <Button className="signupBtn px-4 font-weight-bolder" onClick={handleSubmit}>{t('Update')}</Button>
                                                </Col>
                                            </Row>
                                         
                                    )}
                            </Formik>
                           
                           
                        </Col>
						)
}



export default EditProfile;

