import React, { useState } from 'react'
import { Col, Button, } from "reactstrap";
import classnames from "classnames";
import locationLogo from "../../../assets/images/Group 212.png"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import GoogleMapReact from 'google-map-react';



const autoPlaceStyle = {
    input: (provided) => ({
        ...provided,
        color: '#FF5900',
    }),
    option: (provided) => ({
        ...provided,
        color: '#FF5900',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#FF5900',
    }),
    loadingIndicator: (provided) => ({
        ...provided,
        color: "#FF5900"
    }),
    indicatorSeparator: () => ({
        display: "none"
    }),
    dropdownIndicator: () => ({
        display: 'none'
    })
};

const GoogleMap = ({updateLoctaionCord,defaultLat,defaultLang}) => {


console.log("City :: ",defaultLat)
    const defaultMapLocation = {
    center: {
        lat: parseFloat(defaultLat),
        lng: parseFloat(defaultLang)
    },
    zoom: 11
};

    const [location, setLocation] = useState({ lat: defaultMapLocation.center.lat, lng: defaultMapLocation.center.lng });

    const getPosition = (event) => {
        setLocation({
            lat: event.lat,
            lng: event.lng
        })
       updateLoctaionCord(event.lat, event.lng);
        console.log(event);
    };
   

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                console.log(position)
                setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
                updateLoctaionCord( position.coords.latitude,position.coords.longitude);
            });
         
        }
    }

    return (
        <Col xs="12">
            {/*  <div className="d-flex mb-2">
                <Button size="sm" onClick={getCurrentLocation} color="white" className={classnames("BoxShadow px-3 mr-2 flex-shrink-1 ml-1")}>
                    <img src={locationLogo} className="img-fluid" style={{ width: "25px" }} alt="" />
                </Button>
                <div className="flex-grow-1 d-flex align-items-center position-relative">
                    <i className="fa fa-search"></i>
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyAY1c0iL6AdoEIfc32ck4P5StLIyhnIXZc"
                        selectProps={{
                            placeholder: "Search",
                            className: "BoxShadow autoPlace w-100 pl-4",
                            styles: autoPlaceStyle
                        }}
                    />
                </div>
            </div>*/}
            <div
                style={{ height: '35vh', width: '100%', cursor: "pointer" }}>
                <GoogleMapReact
                    onClick={getPosition}
                    bootstrapURLKeys={{
                        key: "AIzaSyAY1c0iL6AdoEIfc32ck4P5StLIyhnIXZc",
                        language: 'en'

                    }}
                    defaultCenter={location}
                    yesIWantToUseGoogleMapApiInternals={true}
                    center={location}
                    defaultZoom={defaultMapLocation.zoom}>
                    <Marker
                        lat={location.lat}
                        lng={location.lng}
                    />
                </GoogleMapReact>
            </div>
        </Col>
    )
}


const Marker = () => (<i className="fa fa-map-marker"></i>);

export default GoogleMap;

