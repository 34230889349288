 export default function checkFileTooBig(file?: [File]): boolean {
  let valid = true
console.log("file...",file)
  if (file) {
   console.log("File size check..");
    file.map(file1 => {
      const size = file1.size / 1024 / 1024
      if (size > 2) {
        valid = false
      }
    })
  }
  return valid
}