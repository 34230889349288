import React from "react";
import { Row, Col, Input, Button } from "reactstrap";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const SearchOrder = ({ searchedDevice, handleClick }) => {
  const { t, i18n } = useTranslation();
    return (
        <>
        {searchedDevice!=undefined?  searchedDevice.length ? 
           null
              :
            <Col><h5 className="text-center">Order Not Found</h5></Col>
            :
            null
            }
                {searchedDevice.map(device => (
                    <React.Fragment key={device.deviceId + Math.random()}>
                        <Col xs="12" className="BoxShadow  py-2 my-2">
                            <Row>

                                 <Col xs="10">
                                <Row>
                                    <Col xs="6" sm="6" className="d-flex">
                                        <span className="mx-1" style={{ fontSize: '14px' }}>{device.request_id} </span>
                                        
                                                        
                                    </Col>
                                    <Col xs="6" sm="6" className="d-flex align-items-center px-0">

                                             <span className="mx-1" style={{  fontSize: '14', color: device.req_status_id==1 ? 'green' : 'orange'}} > {device.req_status_id=="1" ? t('Accepted') : t('Pending')}</span>
                                            <span className="mx-2" style={{ fontSize: '14', color:'red' }}>{i18next.language=="en" ? device.title : device.title_ar }</span>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="6"  sm="6" className="align-self-center pr-3 d-flex">
                                        
                                        <span className="mx-1">{i18next.language=="en" ? device.service_title : device.service_title_ar }</span><span className="mx-1">{i18next.language=="en" ? <i class="fa fa-angle-right" aria-hidden="true"></i>: <i class="fa fa-angle-left" aria-hidden="true"></i>} </span> {i18next.language=="en" ? device.task_title : device.task_title_ar }
                                    </Col>
                                    
                                    <Col xs="6"  sm="6" className="d-flex align-items-center">
                                        <span>{device.fixing_date!=null ? device.fixing_date : device.order_date}</span>
                                       
                                    </Col>
                                </Row>
                            </Col>
                                <Col xs="2" className="d-flex justify-content-end">
                                    <Button className="RequestBtn" style={{ borderRadius: 10 }} onClick={() => handleClick(device)}>
                                      {i18next.language=="en" ?  <i className="fa fa-chevron-right"></i> : <i className="fa fa-chevron-left"></i> }
                                    </Button>
                                </Col>

                               {/* <Col xs="10">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <span className=" d-inline-block" style={{ minWidth: "93px" }}>{t('Request Id')} </span>:
                                                            <span className="mx-2">{device.request_id} </span>
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <span className=" d-inline-block" style={{ minWidth: "93px" }}> {t('Device Type')}</span>:
                                                            <span className="mx-2">{device.device_model}</span>

                                        </Col>
                                    </Row>
                                  
                                </Col>
                                <Col xs="2" className="d-flex justify-content-end">
                                    <Button className="RequestBtn" style={{ borderRadius: 10 }} onClick={() => handleClick((device)}>
                                        <i className="fa fa-chevron-right"></i>
                                    </Button>
                                </Col>*/}
                            </Row>
                        </Col>
                    </React.Fragment>
                ))}
           
          
        </>
    );
}

export default SearchOrder;