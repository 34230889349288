import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
    UncontrolledPopover,
    PopoverBody,
    Button,
} from "reactstrap";
import { useCookies } from "react-cookie";
const companyDropDown = () => {
    const myAccountItems = [];

    //return  myAccountItems;
}

const myAccountItemsCoompany = [
    { label: "Dashboard", icon: "fa fa-list", link: "/company-home" },
    { label: "Address", icon: "fa fa-address-book-o", link: "/home" },
    { label: "Complain", icon: "fa fa-list-alt", link: "/home" },

];


const myAccountItemsdriver = [
    { label: "Dashboard", icon: "fa fa-list", link: "/driver-home" },
    { label: "Address", icon: "fa fa-address-book-o", link: "/home" },
    { label: "Complain", icon: "fa fa-list-alt", link: "/home" },

];
const myAccountItems = [

    { label: "Home", icon: "fa fa-address-book-o", link: "/admin-home" },
    { label: "My Ads", icon: "fa fa-list-alt", link: "/trackorder" },
    { label: "Profile", icon: "fa fa-user", link: "/my-profile" }
];

const MyAccountDropDown = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(["customer"]);
    const history = useHistory();
    const signOutHandler = () => {
        removeCookie("customer");
        localStorage.clear();
        history.push("/")
        window.location.reload();
    }
    return (
        <>
            <Button id="PopoverLegacy" type="button" size="sm" className="myAccountBtn mb-2 mr-2 ml-2 mt-2">
                Hi {props.firstname}<i className="fa fa-caret-down"></i>

            </Button>
            <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy"  >
                <PopoverBody>
                    {
                        props.user_type_id == 2 ?
                            myAccountItemsCoompany.map((item, ind) => (
                                <Fragment key={item.label}>
                                    <div className="d-flex align-items-center p-2 myAccountListItems" onClick={() => history.push(item.link)}>
                                        <i className={item.icon + " pr-3"}></i>
                                        <span>  {props.t(item.label)}</span>

                                    </div>
                                </Fragment>
                            ))

                            :
                            props.user_type_id == 3 ?
                                myAccountItemsdriver.map((item, ind) => (
                                    <Fragment key={item.label}>
                                        <div className="d-flex align-items-center p-2 myAccountListItems" onClick={() => history.push(item.link)}>
                                            <i className={item.icon + " pr-3"}></i>
                                            <span>  {props.t(item.label)}</span>

                                        </div>
                                    </Fragment>
                                ))
                                :
                                myAccountItems.map((item, ind) => (
                                    <Fragment key={item.label}>
                                        <div className="d-flex align-items-center p-2 myAccountListItems" onClick={() => history.push(item.link)}>
                                            <i className={item.icon + " pr-3"}></i>
                                            <span>  {props.t(item.label)}</span>

                                        </div>
                                    </Fragment>
                                ))

                    }
                    <hr style={{ height: "5px", backgroundColor: "lightgray" }} />
                    <div className="text-center">
                        <Button className="RequestBtn px-4 mb-2 font-weight-bolder" onClick={signOutHandler}>{props.t('Sign Out')}</Button>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </>
    );
}

export default MyAccountDropDown;