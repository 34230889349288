import React, { useState } from "react";
import headerLogo from "../../../assets/images/headerLogo.png";
import {
    Navbar, NavbarToggler, Collapse, Nav, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Modal,
    ModalBody,
    ModalHeader, Row, Col
} from "reactstrap";
import { Link } from "react-router-dom";
import HeaderLink from "./HeaderLinks";
import "./Header.css";
import { NavLink, withRouter } from "react-router-dom";
import menuImage1 from "../../../assets/images/Group 269.png";
import menuImage2 from "../../../assets/images/Group 194.png";
import menuImage3 from "../../../assets/images/Group 308.png";
// import menuImage4 from "../../../assets/images/Group 307.png";
import menuImage5 from "../../../assets/images/Group 269.png";
import menuImage6 from "../../../assets/images/Group 269.png";
import menuImage7 from "../../../assets/images/Group 269.png";
import MyAccountDropDown from "./MyAccountDropDown";
import SocialMediaButtons from "../../Components/ShareButton/SocialMediaButtons";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import useVisible from './useVisible';

import { useCookies } from "react-cookie";
import { useEffect, useRef } from "react";
const Header = (props) => {
    const token = localStorage.getItem('token');

    const firstname = localStorage.getItem('firstname');
    //const  user_type_id=localStorage.getItem('user_type_id');
    //const user_type_id=1;
    const [cookies, setCookie] = useCookies(["user_type_id"]);
    const user_type_id = cookies.user_type_id != "" ? cookies.user_type_id : "";
    console.log("user_type_id", cookies)
    //const { cookies } = props;

    // const user_type_id=cookies.get('user_type_id') ;

    //const [isOpen, setIsOpen] = useState(false);
    const [isShowShare, setIsShowShare] = useState(false);
    const { ref, isOpen, setIsOpen } = useVisible(false,);
    const [defaultLanguage, setdefaultLanguage] = useState('ar');
    const toggle = () => setIsOpen(!isOpen);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { t, i18n } = useTranslation();
    const node = useRef(null);
    console.log(i18n)

    //const { ref, isVisible, setIsVisible } = useVisible(false);

    // const logoutHandler = () => {
    //     localStorage.clear()
    //     props.history.push("/login");
    //     console.log("test")
    // }
    const signUpHandler = () => {
        props.history.push("/signup");
        setIsOpen(false);

    }

    const links = [
        { label: "Home", label_ar: "الرئيسية", label_fr: "Page Principale", img: menuImage1, link: "/" },
        // { label: "My Order Tracking", label_ar: "تتبع طلباتي", img: menuImage2, link: "/trackorder" },


        /**{ label: "Contact Us", label_ar: "إتصل بنا", img: menuImage6, link: "/contactus" },**/
        /** { label: "About Us", label_ar: "من نحن", img: menuImage7, link: "/aboutus" },**/
        /**{ label: "Company",label_ar: "شركة", img: menuImage7, link: "/company-home" },
        { label: "Driver", label_ar: "سائق",img: menuImage7, link: "/driver-home" },**/
    ];

    const dropdownOpenToggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const shareToOthers = () => {

        setIsOpen(false);
        setIsShowShare(true);

    }

    useEffect(() => {
        document.body.dir = i18n.dir();
        // document.addEventListener('click', handleClick, true);
        // document.addEventListener("mousedown",handleClick);  
    })

    const dropdownHandler = (e) => {
        console.log("Language ...", e)

        i18n.changeLanguage(e)
        document.body.dir = i18n.dir();
        setdefaultLanguage(e)



    }


    if (props.history.location.pathname === '/company-home') {
        return null;

    }

    const share_msg_en = "\n  عجبني جدا يوفر خدمات الصيانة من سباكة وكهرباء وصيانة جوالات ومكيفات ومصاعد وشبكات وديكور وغيرها    \n الموقع الإلكتروني  \n";
    const share_msg_ar = "\n  عجبني جدا يوفر خدمات الصيانة من سباكة وكهرباء وصيانة جوالات ومكيفات ومصاعد وشبكات وديكور وغيرها    \n الموقع الإلكتروني  \n";
    const share_msg_fr = "\n  عجبني جدا يوفر خدمات الصيانة من سباكة وكهرباء وصيانة جوالات ومكيفات ومصاعد وشبكات وديكور وغيرها    \n الموقع الإلكتروني  \n";

    return (
        <>
            <Modal isOpen={isShowShare} className="social-modal" backdrop="static">
                <ModalHeader toggle={() => setIsShowShare(false)}>ارسل الموقع لصديق</ModalHeader>
                <ModalBody >
                    <Row>
                        <SocialMediaButtons msg_share={i18next.language == "en" ? share_msg_en : i18next.language == "ar" ? share_msg_ar : share_msg_fr} />
                    </Row>
                </ModalBody>
            </Modal>

            <div class="header">
                <Container>




                    <Navbar color="faded" light expand="lg" style={{ padding: '0px' }} className="d-flex justify-content-between" className={i18next.language == "en" || i18next.language == "fr" ? 'nav-dir-eng' : 'nav-dir-ar'}  >



                        <NavbarToggler onClick={toggle} className="btn-sm" />
                        <NavLink to="/" activeClassName="" className="header-link" onClick={() => setIsOpen(false)}>
                            {/**  <span style={{ color: "#FF5900", marginLeft: 10, fontSize: 17 }}>{t('Maintanance_Services')}</span> **/}


                        </NavLink>
                        <img src={headerLogo} alt="" className="headerLogo" />
                        {<div style={{ display: "contents" }} ref={ref}>



                            <Collapse isOpen={isOpen} navbar className="text-right1" className={i18next.language == "en" || i18next.language == "fr" ? 'left-sidepopup' : 'right-sidepopup'}  >


                                <div class="top-dashboard">
                                    <span>DashBoard</span>
                                    <NavLink to="/" activeClassName="" className="header-link pt-3" className={i18next.language == "en" || i18next.language == "fr" ? 'nav-toggle-eng' : 'nav-toggle-ar'} onClick={() => setIsOpen(false)}>X </NavLink>
                                </div>
                                <div class="popup-side">

                                    <Nav navbar>


                                        {
                                            links.map(link => (
                                                <HeaderLink
                                                    key={link.label}
                                                    hideToggler={() => setIsOpen(false)}
                                                    linkName={i18next.language == "en" ? link.label : i18next.language == "ar" ? link.label_ar : link.label_fr}
                                                    imgPath={link.img}
                                                    link={link.link}
                                                    t={t}



                                                />
                                            ))
                                        }
                                        <li class="mb-0 nav-item"><a class="NavLink" onClick={() => shareToOthers()} aria-current="page">{t('Share')}</a></li>
                                        {/**<Button
                                        className="NavLink border-0 "
                                        size="sm"
                                        onClick={() => shareToOthers()}>{t('Share')}</Button>**/}


                                        {

                                            !token || !user_type_id ?
                                                <>
                                                    <HeaderLink
                                                        linkName={t('signin')}
                                                        link="/login"
                                                        imgPath={menuImage6}
                                                    />

                                                </>
                                                : <MyAccountDropDown user_type_id={user_type_id} t={t} firstname={firstname} />

                                        }








                                    </Nav>



                                </div>

                                <div class="overlay" onClick={toggle}></div>

                            </Collapse>
                            <div class="language_drop">
                                <Dropdown className="mb-2 ml-2 " style={{ marginTop: '3px' }} isOpen={dropdownOpen} toggle={dropdownOpenToggle}>
                                    <DropdownToggle caret>
                                        {i18next.language == "en" ? t('English') : i18next.language == "ar" ? t('Arabic') : t('French')}

                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem defaultValue onClick={() => dropdownHandler('en')}>{t('English')}</DropdownItem>
                                        <DropdownItem onClick={() => dropdownHandler('ar')}>{t('Arabic')}</DropdownItem>
                                        <DropdownItem onClick={() => dropdownHandler('fr')}>{t('French')}</DropdownItem>

                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                        </div>}

                    </Navbar>
                </Container>
            </div>
        </>
    );
};

export default withRouter(Header);