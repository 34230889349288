import React, { Component } from "react";
import { Container, Row, Col, Label, Input, Button, InputGroup, FormGroup, FormFeedback ,  Modal,
    ModalBody,
    ModalHeader} from "reactstrap";
import sideImage from "../../../assets/images/Group 426.png"
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import Footer from "../../Components/Footer";
import { Post,Get } from "../../../utilities/axiosRequest";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { useHistory } from 'react-router-dom';
import ToastBox from "../../Components/ToastBox/ToastBox";
const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
           .required("E-mail or username is required"),
    password: Yup.string()
        .trim("No white space allow in starting")
        .required("Password is required")
});

class Privacy extends Component {
     
    render() {
        const {t}=this.props;
        return (
            <>
                <Container>
                    <Row className="loginPage">
                        <Col md="6" className="align-self-center">
                           
                            <h3 style={{ color: "darknavy" ,textAlign:"center"}}>{t('Privacy Policy')}</h3>
                             <p> Adil built the المنادي الذكي app as a Free app. This SERVICE is provided by Adil at no cost and is intended for use as is. This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service. If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at المنادي الذكي unless otherwise defined in this Privacy Policy. </p>
                            <h2>Information Collection and Use</h2>
                            <p> Information Collection and Use For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information, including but not limited to اسم الطالب, اسم المعلم. The information that I request will be retained on your device and is not collected by me in any way. 

                            </p>
                            <h2>Log Data</h2>
                            <p>
                                 I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics. 

                            </p>
                            <h2>Service Providers</h2>
                            <p>
    To facilitate our Service
    To provide the Service on our behalf
    To perform Service-related services
    To perform Service-related services

I want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose</p>

                        </Col>
                        <Col md="6" className="d-none d-md-block align-self-center">
                            <img src={sideImage} alt="" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
};

export default withTranslation()(withCookies(Privacy));