import React from "react";
import { Row, Col, Input, Button,PaginationItem,
    PaginationLink } from "reactstrap";
import i18next from 'i18next';
import Pagination from "react-js-pagination";
import axios from 'axios';
const SearchOrder = ({ requests, handleClick ,activePage,page,itemsCountPerPag,totalItemsCount,handlePageChange}) => {
    
    return (
        <>
		{/*<Col xs="12" sm="6" className="mx-auto my-2 d-flex align-items-center">
                <span className="mr-2">Name</span>
                <Input className="BoxShadow" type="text" value={searchedDevice[0].name} disabled />
		</Col>*/}
            {requests.map(order => (
                <React.Fragment key={order.request_id + Math.random()}>
                    <Col xs="12" className="BoxShadow  py-2 my-2">
                        <Row>
                            <Col xs="10">
                                <Row>
                                    <Col xs="6" sm="6" className="d-flex">
                                        <span className="mx-1" style={{ fontSize: '14px' }}>{order.request_id}</span>
                                         <span className="mx-1" style={{ fontSize: 'xx-medium' }}>
                                           <i class="fa fa-phone-square" aria-hidden="true"></i>
                                        </span>
														
                                    </Col>
                                    <Col xs="6" sm="6" className="d-flex align-items-center px-0">

											 <span className="mx-1" style={{  fontSize: '14', color: order.req_status_id==1 ? 'green' : 'orange'}} > {order.req_status_id=="1" ? 'Accepted' : 'Pending'}</span>
											<span className="mx-2" style={{ fontSize: '14', color:'red' }}>sss</span>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="8"  sm="6" className="align-self-center pr-3 d-flex">
                                         <span className="ml-1 " style={{ fontSize: 'xx-medium',color:'green' }}>
                                          <a href="https://wa.me/" style={{ fontSize: 'xx-medium',color:'green' }}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                        </span>
                                        <span className="mx-1">{order.device_model}</span><span className="mx-1">{i18next.language=="en" ? <i class="fa fa-angle-right" aria-hidden="true"></i>: <i class="fa fa-angle-left" aria-hidden="true"></i>} </span>  {order.device_fix=="2" ? 'Fix' : 'Buy New'}
                                    </Col>
									
                                    <Col xs="4"  sm="6" className="d-flex align-items-center">
                                        <span>{order.fixing_date}</span>
                                       
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="2" className="d-flex btn-end">
                                <Button className="RequestBtn" style={{ borderRadius: 10 }} onClick={() => handleClick(order)}>
                                  {i18next.language=="en" ?  <i className="fa fa-chevron-right"></i> : <i className="fa fa-chevron-left"></i> }
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </React.Fragment>
            ))}
             <Pagination
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          itemClass="page-item" 
                          linkClass="page-link" 
                          onChange={handlePageChange}
                        />

        </>
    );
}

export default SearchOrder;